import React, { useState } from "react";
import Results from "./Results";

function ResultsDrawer({ openResultsDrawer }) {
  return (
    <div
      className={`drawer fixed z-30 max-h-screen w-full overflow-y-auto bg-white transition-all duration-500 dark:bg-black md:hidden ${
        openResultsDrawer ? "top-0" : "top-full"
      }`}
    >
      <div className="drawer-content mb-32 mt-72 px-4">
        <div className="mx-auto w-full max-w-sm">
          <Results />
        </div>
      </div>
    </div>
  );
}

export default ResultsDrawer;
