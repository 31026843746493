const getDefaultClasses = () => {
  return "flex items-center leading-none rounded-full uppercase whitespace-nowrap disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed";
};

const getBackgroundClasses = (color) => {
  switch (color) {
    case "blueviolet":
      return "bg-blueviolet text-white hover:bg-blueviolet-600 focus:outline-none focus:ring-2 focus:ring-blueviolet focus:ring-offset-2";
    case "emerald":
      return "bg-emerald-500 text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2";
    case "sky":
      return "bg-sky-500 text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2";
    case "white":
      return "border border-gray-600 bg-white text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-400 focus:ring-offset-white";
    case "plain":
      return "bg-gray-100 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white";
    case "plainActive":
      return "bg-gray-900 text-white focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 focus:ring-offset-white";
  }
};

export const ButtonDefault = ({
  color,
  label,
  icon = null,
  onClick,
  disabled = false,
}) => {
  return (
    <button
      type="button"
      className={`${getDefaultClasses()} px-3 py-2 text-xs font-semibold sm:text-sm ${getBackgroundClasses(
        color
      )}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {label}
    </button>
  );
};

export function ButtonLarge({ color, label, onClick, disabled = false }) {
  return (
    <button
      type="button"
      className={`${getDefaultClasses()} px-6 py-4 text-xl font-semibold ${getBackgroundClasses(
        color
      )}`}
      onClick={onClick}
      disabled={disabled}
      selected={true}
    >
      {label}
    </button>
  );
}

export function ButtonSmall({ color, label, onClick, disabled = false }) {
  return (
    <button
      type="button"
      className={`${getDefaultClasses()} px-4 py-2 text-xs font-semibold ${getBackgroundClasses(
        color
      )}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
