import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { ButtonSmall } from "../components/Buttons";
import RepresentativeExample from "../components/RepresentativeExample";
import Tooltip from "./Tooltip";

import { priceFormatter } from "../utils/PriceFormatter";
import { percentageFormatter } from "../utils/percentageFormatter";
import { filter } from "lodash";
import { object } from "joi";

import { calculatePayableOverTermIncludeFees } from "../utils/productCalculations";
import AppContext from "../Context/AppContext";

const ProductCard = ({
  product,
  getLenderLogo,
  handleInfoButton,
  handleRemoveProduct,
}) => {
  const { referral } = useContext(AppContext);

  let navigate = useNavigate();
  let location = useLocation();

  //   const logo = location.state
  //     ? location.state.logo
  //     : getLenderLogo(product.lenderID, product.lenderCommonName);

  const calculatePayableOverTerm = () => {
    const initialMonthlyPaymentTotal =
      product.initialMonthlyPayment.toFixed(2) * product.rateControlPeriod;

    const finalMonthlyPaymentTotal =
      (product.term * 12 - product.rateControlPeriod) *
      product.finalMonthlyPayment.toFixed(2);

    // product.initialMonthlyPayment * product.rateControlPeriod +
    // (product.term * 12 - product.rateControlPeriod) *
    //   product.finalMonthlyPayment +
    // product.totalFees +
    // product.finalFee -
    // product.cashback,

    return parseFloat(initialMonthlyPaymentTotal + finalMonthlyPaymentTotal);
  };

  const toolTipContent = {
    initialRate: (
      <p className="text-md text-white">
        The rate charged during the introductory period of a mortgage
      </p>
    ),
    typeDuration: (
      <p className="text-md text-white">
        The type of mortgage, for example, fixed or tracker and the date any
        introductory rate ends.
      </p>
    ),
    maxLTV: (
      <p className="text-md text-white">
        Loan to value, or LTV is a way of measuring how much money you’re
        borrowing, compared to the total value of your property. LTV is
        expressed as a percentage.
      </p>
    ),
    initialMonthlyPayment: (
      <p className="text-md text-white">
        Your monthly payment when your mortgage begins, based on the mortgage
        amount you entered and interest due.
      </p>
    ),
    totalInitialFees: (
      <p className="text-md text-white">
        The money you will pay to arrange the mortgage, for example, booking,
        arrangement and valuation fees. This does not include a final fee (if
        applicable).
      </p>
    ),

    totalInitialCost: (
      <p className="text-md text-white">
        The total cost of your monthly payments over the initial{" "}
        {product.graphRateSteps[0].rateType.toLowerCase()} period including
        fees. This does not include a final fee (if applicable).
      </p>
    ),

    overallCostForComparison: (
      <p className="text-md text-white">
        APRC stands for annual percentage rate of charge. It shows you, as a
        percentage, the total cost of your mortgage, including fees, over the
        full term of the loan.
      </p>
    ),
  };

  return (
    <div className="mb-8 rounded-lg border border-gray-200 bg-white p-2 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50 md:p-4">
      <div className="grid w-full grid-cols-1 divide-y divide-gray-200 border-b border-gray-200 dark:divide-gray-800 dark:border-gray-800 sm:grid-cols-4 sm:divide-x sm:divide-y-0">
        {/* <img
          src={`data:image/${logo[
            "a:ImageSubType"
          ]._text.toLowerCase()};base64,${logo["a:Data"]._text}`}
          alt={product.lenderCommonName}
          className="max-h-12"
        /> */}

        <div className="flex w-full justify-between p-2 sm:flex-col sm:justify-start">
          <div className="flex w-fit rounded-lg bg-white p-2">
            <div className={`_${product.lenderID}`}></div>
          </div>
        </div>

        <div className="flex w-full justify-between p-2 sm:flex-col sm:justify-start">
          <div className="flex w-full justify-between sm:block">
            <div className="flex items-center justify-between sm:items-start">
              <span className="pr-2 text-sm text-black dark:text-white">
                Initial rate
              </span>
              <span className="hidden sm:block">
                <Tooltip content={toolTipContent.initialRate} />
              </span>
            </div>
            <span className="text-right font-bold text-black dark:text-white sm:text-left">
              {percentageFormatter(product.initialRate)}
            </span>
          </div>
          <div className="theIcon  ml-2 block sm:ml-0 sm:hidden">
            <Tooltip content={toolTipContent.initialRate} />
          </div>
        </div>

        <div className="flex w-full justify-between p-2 sm:flex-col sm:justify-start">
          <div className="flex w-full justify-between sm:block">
            <div className="flex items-center justify-between sm:items-start">
              <span className="pr-2 text-sm text-black dark:text-white">
                Type / Duration
              </span>
              <span className="hidden sm:block">
                <Tooltip content={toolTipContent.typeDuration} />
              </span>
            </div>
            <span className="text-right text-sm font-bold text-black dark:text-white sm:text-left">
              {product.note}
            </span>
          </div>
          <div className="theIcon  ml-2 block sm:ml-0 sm:hidden">
            <Tooltip content={toolTipContent.typeDuration} />
          </div>
        </div>

        <div className="flex w-full justify-between p-2 sm:flex-col sm:justify-start">
          <div className="flex w-full justify-between sm:block">
            <div className="flex items-center justify-between sm:items-start">
              <span className="pr-2 text-sm text-black dark:text-white">
                Max LTV
              </span>
              <span className="hidden sm:block">
                <Tooltip content={toolTipContent.maxLTV} />
              </span>
            </div>
            <span className="text-right font-bold text-black dark:text-white sm:text-left">
              {percentageFormatter(product.maxLTV)}
            </span>
          </div>
          <div className="theIcon  ml-2 block sm:ml-0 sm:hidden">
            <Tooltip content={toolTipContent.maxLTV} />
          </div>
        </div>
      </div>

      <div className="mb-2 grid w-full grid-cols-1 divide-y divide-gray-200 dark:divide-gray-800 sm:grid-cols-4 sm:divide-x sm:divide-y-0">
        <div className="flex w-full justify-between p-2 sm:flex-col sm:justify-start">
          <div className="flex w-full justify-between sm:block">
            <div className="flex items-center justify-between sm:items-start">
              <span className="pr-2 text-sm text-black dark:text-white">
                Initial monthly payment
              </span>
              <span className="hidden sm:block">
                <Tooltip content={toolTipContent.initialMonthlyPayment} />
              </span>
            </div>
            <span className="text-right font-bold text-black dark:text-white sm:text-left">
              {priceFormatter(product.initialMonthlyPayment)}
            </span>
          </div>
          <div className="theIcon  ml-2 block sm:ml-0 sm:hidden">
            <Tooltip content={toolTipContent.initialMonthlyPayment} />
          </div>
        </div>

        <div className="flex w-full justify-between p-2 sm:flex-col sm:justify-start">
          <div className="flex w-full justify-between sm:block">
            <div className="flex items-center justify-between sm:items-start">
              <span className="pr-2 text-sm text-black dark:text-white">
                Total initial fees
              </span>
              <span className="hidden sm:block">
                <Tooltip content={toolTipContent.totalInitialFees} />
              </span>
            </div>
            <span className="text-right font-bold text-black dark:text-white sm:text-left">
              {priceFormatter(product.totalInitialFees)}
            </span>
          </div>
          <div className="theIcon  ml-2 block sm:ml-0 sm:hidden">
            <Tooltip content={toolTipContent.totalInitialFees} />
          </div>
        </div>

        {product.graphRateStepsCount > 1 ? (
          <div className="flex w-full justify-between p-2 sm:flex-col sm:justify-start">
            <div className="flex w-full justify-between sm:block">
              <div className="flex items-center justify-between sm:items-start">
                <span className="pr-2 text-sm text-black dark:text-white">
                  Total cost for initial period
                </span>
                <span className="hidden sm:block">
                  <Tooltip content={toolTipContent.totalInitialCost} />
                </span>
              </div>
              <span className="text-right font-bold text-black dark:text-white sm:text-left">
                {priceFormatter(product.totalInitialCost)}
              </span>
            </div>
            <div className="theIcon  ml-2 block sm:ml-0 sm:hidden">
              <Tooltip content={toolTipContent.totalInitialCost} />
            </div>
          </div>
        ) : null}

        <div
          className={`flex w-full justify-between p-2 sm:flex-col sm:justify-start ${
            product.graphRateStepsCount === 1 ? "col-auto sm:col-span-2" : null
          }`}
        >
          <div className="flex w-full justify-between sm:block">
            <div className="flex items-center justify-between sm:items-start">
              <span className="pr-2 text-sm text-black dark:text-white">
                Overall cost for comparison
              </span>
              <span className="hidden sm:block">
                <Tooltip content={toolTipContent.overallCostForComparison} />
              </span>
            </div>
            <span className="text-right font-bold text-black dark:text-white sm:text-left">
              {percentageFormatter(product.APRC)} APRC
            </span>
          </div>
          <div className="theIcon  ml-2 block sm:ml-0 sm:hidden">
            <Tooltip content={toolTipContent.overallCostForComparison} />
          </div>
        </div>
      </div>

      <div className="mb-2 flex">
        <ButtonSmall
          color="plain"
          label={`${product.infoButton ? "Less" : "More"} info`}
          onClick={() => handleInfoButton(product.schemeID)}
        />
      </div>
      {product.infoButton && (
        <>
          <div className="mb-2 grid w-full grid-cols-1 border border-gray-200 dark:border-gray-800 sm:grid-cols-3">
            <div className="w-full sm:col-span-2">
              <div className="grid grid-cols-1 sm:grid-cols-2">
                <div className="col-span-1 p-2 pb-1 sm:col-span-2">
                  <p className="text-sm font-bold text-black dark:text-white">
                    Fees and charges
                  </p>
                </div>

                <div className="p-2 pb-1">
                  {/* <p className=" mb-2 text-sm font-bold text-black dark:text-white">
                    Fees and Charges
                  </p> */}

                  <div className="flex w-full justify-between p-1">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Booking fee
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.bookingFee)}
                    </span>
                  </div>
                  <div className="flex w-full justify-between p-1">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Arrangement fee
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.arrangementFee)}
                    </span>
                  </div>
                  <div className="flex w-full justify-between p-1">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Valuation fee
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.valuationFee)}
                    </span>
                  </div>
                  <div className="flex w-full justify-between p-1">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Other fee
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.otherFee)}
                    </span>
                  </div>
                  {/* <div className="flex w-full justify-between bg-purple-100 p-1 dark:bg-purple-900">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Total fees
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.totalFees)}
                    </span>
                  </div>

                  <div className="flex w-full justify-between bg-purple-100 p-1 dark:bg-purple-900">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Final fee
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.finalFee)}
                    </span>
                  </div> */}
                </div>
                <div className="p-2 pb-1">
                  {/* <p className="mb-2 text-sm font-bold text-black dark:text-white"></p> */}
                  <div className="flex w-full justify-between p-1">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Telegraphic transfer fee
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.telegraphicTransferFee)}
                    </span>
                  </div>
                  <div className="flex w-full justify-between p-1">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Valuation admin fee
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.valuationAdminFee)}
                    </span>
                  </div>
                  <div className="flex w-full justify-between p-1">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Cashback
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.cashback)}
                    </span>
                  </div>
                  <div className="flex w-full justify-between p-1">
                    <span className="pr-2 text-xs text-black dark:text-white">
                      Final fee
                    </span>
                    <span className="text-xs font-bold text-black dark:text-white">
                      {priceFormatter(product.finalFee)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-t border-gray-200 dark:border-gray-800 sm:border-l sm:border-t-0">
              <div className="col-span-1 p-2 pb-1 sm:col-span-2">
                <p className="text-sm font-bold text-black dark:text-white">
                  Flexibility
                </p>
              </div>

              <div className="p-2 pb-1">
                <div className="flex w-full justify-between p-1">
                  <span className="pr-2 text-xs text-black dark:text-white">
                    Overpayments allowed?
                  </span>
                  <span className="text-xs font-bold text-black dark:text-white">
                    {product.overpayments ? "Yes" : "No"}
                  </span>
                </div>
                <div className="flex w-full justify-between p-1">
                  <span className="pr-2 text-xs text-black dark:text-white">
                    Payment holidays
                  </span>
                  <span className="text-xs font-bold text-black dark:text-white">
                    {product.paymentHolidays ? "Yes" : "No"}
                  </span>
                </div>

                <div className="flex w-full flex-col justify-start p-1">
                  <span className="pr-2 text-xs text-black dark:text-white">
                    Early repayment charges:
                  </span>
                  <span className="text-xs font-bold text-black dark:text-white">
                    {product.ERCDescription}
                  </span>
                </div>
              </div>
            </div>
            <div className="p2 col-span-1 border-t border-gray-200 p-2 dark:border-gray-800 sm:col-span-3">
              <p className="text-xs font-bold text-black dark:text-white">
                {product.rateDescription}
              </p>
            </div>

            <RepresentativeExample product={product} />
          </div>

          {/* <div className="my-8 grid w-full grid-cols-1 gap-4 divide-y divide-gray-200 border border-gray-500 dark:divide-gray-800">
            <p className="text-lg">Calculations box</p>
            <p className="bg-yellow-100 text-sm">
              Total Amount Payable Over Term{" "}
              <span className="text-xs">( Figure provided by MB )</span> ={" "}
              <span className="font-bold">
                {priceFormatter(product.totalAmountPayableOverTerm, 2)}
              </span>
            </p>
            <p className="bg-sky-100 text-sm">
              Calculated Payable Over Term ={" "}
              <span className="font-bold">
                {priceFormatter(calculatePayableOverTerm(product), 2)}
              </span>
            </p>
            <p className="text-sm">
              <span className="bg-sky-100">Calculated Payable Over Term</span> +{" "}
              <span className="bg-purple-100">Total Fees</span> +{" "}
              <span className="bg-purple-100">Final Fee</span> -{" "}
              <span className="bg-green-100">Cashback</span> ={" "}
              <span className="font-bold">
                {priceFormatter(
                  calculatePayableOverTermIncludeFees(product),
                  2
                )}
              </span>
            </p>
            <p className="text-sm">
              Difference ={" "}
              <span className="font-bold">
                {priceFormatter(product.difference, 2)}
              </span>
            </p>
            <p className="text-sm">
              Graph Rate Steps ={" "}
              <span className="font-bold">{product.graphRateStepsCount}</span>
            </p>
          </div> */}
        </>
      )}

      {/* <div className="grid w-full grid-cols-1 divide-y divide-gray-200 dark:divide-gray-800 sm:grid-cols-2 sm:divide-x sm:divide-y-0"> */}
      <div className="border-t border-gray-200 pt-2 dark:border-gray-800">
        {location.state ? (
          <div className="flex justify-end">
            <button
              className="text-xs text-gray-500 hover:underline"
              onClick={handleRemoveProduct}
            >
              Remove
            </button>
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center justify-center gap-2 xs:flex-row xs:items-center xs:justify-end xs:gap-4">
              {/* <div className="order-last xs:order-none"> */}
              {/* <button
                onClick={() => document.getElementById("root").scrollIntoView()}
                className="cursor-pointer text-xs text-gray-500 hover:underline"
              >
                Change search criteria
              </button> */}
              <ButtonSmall
                color="white"
                label="Change mortgage search"
                onClick={() => document.getElementById("root").scrollIntoView()}
              />
              {/* </div> */}

              {/* <div className="flex items-center">
              <span
                className="mr-2 text-3xl font-light text-gray-400
              "
              >
                3:
              </span> */}

              <ButtonSmall
                color="emerald"
                label="Book an appointment"
                onClick={() =>
                  navigate("/contact", {
                    state: {
                      product: product,
                      // logo: logo,
                    },
                  })
                }
              />
              {/* </div> */}
            </div>

            {referral && (
              <p className="mt-2 text-center xs:text-right">
                <a className="text-sm hover:underline" href="tel:03458945522">
                  Call: 03458 945 522
                </a>
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
