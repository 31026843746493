const Title = ({ title, stage, align = "center" }) => {
  return (
    <div className={`flex items-center justify-${align}`}>
      {/* <div className="-mr-3 flex h-16 w-16 items-center justify-center rounded-full bg-gray-100 text-4xl font-light text-indigo-500 dark:bg-gray-900 xs:-mr-4 xs:h-20 xs:w-20 xs:text-5xl">
        {stage}
      </div> */}

      <h2 className="text-2xl font-bold leading-none text-black dark:text-white xs:text-3xl">
        {title}
      </h2>
    </div>
  );
};

export default Title;
