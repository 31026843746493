import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonLarge } from "../Buttons";
import AppContext from "../../Context/AppContext";
import Title from "../Title";

function FindMyMortgage(props) {
  const { calculatedPropertyPurchasePrice, showResults, referral } =
    useContext(AppContext);

  let navigate = useNavigate();

  return (
    <>
      {calculatedPropertyPurchasePrice || showResults ? (
        <div className="mb-8 rounded-lg bg-white p-8 shadow-xl shadow-purple-500/25 dark:bg-black/50 dark:shadow-purple-500/50">
          {/* <h2 className="mb-4 text-center text-3xl leading-none text-black dark:text-white">
            Available rates
          </h2> */}

          {/* <div className="mb-2 flex items-center justify-center"> */}
          {/* <div className="-mr-4 flex h-20 w-20 items-center justify-center rounded-full bg-gray-100 text-5xl font-light text-gray-500 dark:bg-gray-900">
              2
            </div> */}

          <h2 className="mb-2 text-center text-3xl leading-none text-black dark:text-white">
            Available rates
          </h2>
          {/* </div> */}

          <p className="mb-2 text-center text-black dark:text-white">
            Search for a mortgage that best suits your circumstances based on
            the information you've provided.
          </p>
          <div className="mb-0 flex justify-center">
            <ButtonLarge
              color="emerald"
              label="Available rates"
              onClick={() => navigate("/find-mortgage")}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default FindMyMortgage;
