import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

function Footer() {
  const location = useLocation();

  return (
    <>
      {/* <div class="tri h-24 md:h-48">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          class="h-full w-full fill-current text-gray-100"
        >
          <path d="M1280,0V192H0Z" />
        </svg>
      </div> */}

      <footer>
        <>
          <div className="bg-gray-100 px-4 py-16 md:px-6 lg:px-8">
            <div className="mx-auto w-full max-w-4xl">
              <div className="mb-8 flex flex-col items-center gap-4 text-center text-sm">
                <p>
                  Threshold Mortgage Advice is a trading name of Threshold
                  Financial Services Limited.
                </p>
                <p>
                  Registered in England. Registered number 5321241. Registered
                  Address: 1 & 2 The Brookside Centre, Auckland Road,
                  Southampton, Hampshire, SO15 0SD.
                </p>

                <p>
                  Threshold Financial Services Limited is an appointed
                  representative of The Openwork Partnership, a trading style of
                  Openwork Limited which is authorised and regulated by the
                  Financial Conduct Authority.
                </p>

                <p>Approved by The Openwork Partnership on 10/08/2023</p>
              </div>

              <div className="flex justify-center text-xs">
                <p>
                  &copy; Copyright {moment().format("YYYY")} - Threshold
                  Financial Services
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              width: "1px",
              height: "1px",
              padding: "0",
              margin: "-1px",
              overflow: "hidden",
              clip: "rect(0, 0, 0, 0)",
              whiteSpace: "nowrap",
              borderWidth: "0",
            }}
          >
            <p>
              <a href="www.pearldesign.uk" target="_blank">
                Website design
              </a>{" "}
              by Pearl Design
            </p>
          </div>
        </>
      </footer>
    </>
  );
}
export default Footer;
