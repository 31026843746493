import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Hero from "../components/Hero";
import { ReactComponent as TaylorWimpeyLogo } from "../img/taylor-wimpey-logo.svg";
import HomePageImage from "../img/home-page-image.jpg";

import Title from "../components/Title";
import { ButtonDefault } from "../components/Buttons";
import AboutThreshold from "../components/widgets/AboutThreshold";
import ReviewsBox from "../components/ReviewsBox";
import TrustpilotLogo from "../components/svg/TrustpilotLogo";
import BlockAbout from "../components/BlockAbout";
import BlockReview from "../components/BlockReview";
import BlockAwards from "../components/BlockAwards";
import AppContext from "../Context/AppContext";

const HomePage = () => {
  let navigate = useNavigate();
  const { domain, referral } = useContext(AppContext);

  return (
    <>
      <div className=" bg-gray-100">
        <Hero
          content={
            <h2 className="text-center text-3xl font-bold text-white">
              Threshold Mortgages
              <br />
              <span className="text-2xl">
                Experts in providing mortgage advice for new build homes
              </span>
            </h2>
          }
          navigation={false}
        />

        <div className="px-4 pb-16 md:px-6 lg:px-8">
          <div className="mx-auto w-full max-w-3xl lg:max-w-4xl">
            {/* Start Here Block */}
            <div className="frost relative z-10 -mt-24 mb-8 w-full rounded-lg border border-gray-200 bg-white p-4 pb-0 shadow-xl shadow-purple-500/25 sm:-mt-32">
              <div className="grid auto-cols-min grid-cols-1 justify-items-center lg:grid-cols-2 lg:gap-8">
                <div className="flex w-full flex-col">
                  <div className="mb-4">
                    <div className="gradient-45 rounded-3xl p-[2px]">
                      <button
                        className="w-full rounded-[calc(1.5rem-2px)] bg-gray-100 p-4 text-left text-black transition-colors hover:bg-transparent hover:text-white"
                        onClick={() => navigate("/find-budget")}
                      >
                        <p className="mb-2 text-xl font-bold uppercase">
                          Mortgage calculator
                        </p>
                        <p className="text-sm">
                          Click here to find your budget with our easy to use
                          mortgage payment calculator.
                        </p>
                      </button>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="gradient-45 w-full rounded-3xl p-[2px]">
                      <button
                        className="w-full rounded-[calc(1.5rem-2px)] bg-gray-100 p-4 text-left text-black transition-colors hover:bg-transparent hover:text-white"
                        onClick={() => navigate("/find-mortgage")}
                      >
                        <p className="mb-2 text-xl font-bold uppercase">
                          Available rates
                        </p>
                        <p className="text-sm">
                          Click here to search current mortgage deals based on
                          your affordability to find the right deal for you.
                        </p>
                      </button>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="gradient-45 w-full rounded-3xl p-[2px]">
                      <button
                        className="w-full rounded-[calc(1.5rem-2px)] bg-gray-100 p-4 text-left text-black transition-colors hover:bg-transparent hover:text-white"
                        onClick={() => navigate("/contact")}
                      >
                        <p className="mb-2 text-xl font-bold uppercase">
                          Book an appointment
                        </p>
                        <p className="text-sm">
                          Click here to arrange a time for an adviser to call
                          you. We don't charge a fee for our service and we are
                          open 7 days a week
                        </p>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col justify-between">
                  {referral === "t" ? (
                    <div className="mb-4 flex items-center justify-end py-2">
                      <p className="mb-4 mr-2 text-xs text-black">
                        Recommended by:
                      </p>
                      <TaylorWimpeyLogo />
                    </div>
                  ) : null}
                  <div
                    className="h-48 rounded-3xl bg-cover bg-center sm:h-72 lg:flex-auto"
                    style={{ backgroundImage: `url(${HomePageImage})` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlockAbout />

      <BlockReview />

      <BlockAwards />
    </>
  );
};

export default HomePage;
