import { Link, useNavigate, useLocation } from "react-router-dom";
import { ButtonDefault, ButtonLarge } from "./Buttons";

const Navigation = (props) => {
  let navigate = useNavigate();
  let location = useLocation();

  const { pathname } = location;

  return (
    <>
      <div className="mx-auto w-full max-w-7xl px-4 md:px-6 lg:px-8">
        <nav className="mt-16 flex w-full flex-wrap justify-start gap-4">
          <ButtonDefault
            color={pathname === "/find-budget" ? "plainActive" : "plain"}
            label="Calculate my budget"
            onClick={() => navigate("/find-budget")}
          />
          <ButtonDefault
            color={pathname === "/find-mortgage" ? "plainActive" : "plain"}
            label="Available rates"
            onClick={() => navigate("/find-mortgage")}
          />
          <ButtonDefault
            color={pathname === "/contact" ? "plainActive" : "plain"}
            label="Book an appointment"
            onClick={() => navigate("/contact")}
          />
        </nav>
      </div>
    </>
  );
};

export default Navigation;
