import React, { useState, useEffect, Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import { has, orderBy } from "lodash";
import axios from "axios";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import HomePage from "./pages/HomePage";
import CalculationsPage from "./pages/CalculationsPage";
import FindMortgagePage from "./pages/FindMortgagePage";
import ApplicationPage from "./pages/ApplicationPage";
import ContactPage from "./pages/ContactPage";
import NotFoundPage from "./pages/NotFoundPage";
import AnimatedBackground from "./components/AnimatedBackground";
import Footer from "./components/Footer";
import Modal from "./components/Modal";
import AppContext from "./Context/AppContext";
import TagManager from "react-gtm-module";

import "./App.css";
import { boolean } from "joi";

function App() {
  const [formData, setFormData] = useState({});
  const [applicationData, setApplicationData] = useState({});
  // const [openResultsPanel, setOpenResultsPanel] = useState(false);
  // const [appointment, setAppointment] = useState(false);
  const [search, setSearch] = useState(false);
  const [domain, setDomain] = useState("");
  const [referral, setReferral] = useState(null);
  const [calculatorUsedEvent, setCalculatorUsedEvent] = useState(false);
  const [searchUsedEvent, setSearchUsedEvent] = useState(false);
  const [appointmentSubmittedEvent, setAppointmentSubmittedEvent] =
    useState(false);
  const [calculatedPropertyPurchasePrice, setCalculatedPropertyPurchasePrice] =
    useState(null);
  const [calculatedLaonAmount, setCalculatedLaonAmount] = useState(null);
  const [formComplete, setFormComplete] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [modal, setModal] = useState({ modalOpen: false, modalContent: null });
  const [rates, setRates] = useState([]);
  const [lowestRates, setLowestRates] = useState([]);
  const [propertyRegionOptions, setPropertyRegionOptions] = useState([]);
  const [schemeOptions, setSchemeOptions] = useState([]);
  const [enquiryOptions, setEnquiryOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [contactFormSubmitted, setContactFormSubmitted] = useState(false);

  useEffect(() => {
    const domainId =
      window.location.hostname === "findmythreshold.io" ||
      window.location.hostname === "www.findmythreshold.io"
        ? "thr"
        : "default";
    setDomain(domainId);
    //process.env.NODE_ENV === "development" && setDomain("thr");
    setDomain("thr");

    // sticky footer (tailwindcss)
    const root = document.getElementById("root");
    root.classList.add("flex", "flex-col", "min-h-screen");

    const main = document.getElementsByTagName("main")[0];
    main.classList.add("flex-1");
    //end sticky footer

    const params = new URLSearchParams(window.location.search);
    const ref = params.get("ref");
    if (!referral) setReferral(ref);
  }, []);

  useEffect(() => {
    const fetchDataFromDb = async () => {
      try {
        await Promise.all([
          axios.get(
            process.env.REACT_APP_API_URL + "/api/best-buy-tables/rates/"
          ),
          axios.get(
            process.env.REACT_APP_API_URL + "/api/property-region-options/"
          ),
          axios.get(process.env.REACT_APP_API_URL + "/api/scheme-options/"),
          axios.get(process.env.REACT_APP_API_URL + "/api/enquiry-options/"),
          axios.get(process.env.REACT_APP_API_URL + "/api/title-options/"),

          //
        ]).then((res) => {
          // set rates
          const rateData = res[0].data;

          let lowestFoundRates = {};
          const rateGroups = ["residential", "buyToLet", "sharedOwnership"];

          rateGroups.forEach((group) => {
            let filteredGroup = rateData.filter((item) => item[group]);

            filteredGroup = orderBy(filteredGroup[0][group], "rate", "asc");

            const lowestFoundRate = filteredGroup.find((item) => {
              return item.rate > 0;
            });

            lowestFoundRates[group] = lowestFoundRate.rate;
          });
          setLowestRates(lowestFoundRates);
          setRates(rateData);

          /// Test this function
          const sortOptions = (data) => {
            const filteredData = data.filter((item) => item.display === true);
            const sortedData = orderBy(filteredData, "order", "asc");

            return sortedData;
          };

          // set property region options
          setPropertyRegionOptions(sortOptions(res[1].data));

          // scheme options
          setSchemeOptions(sortOptions(res[2].data));

          // set enquiry options
          setEnquiryOptions(sortOptions(res[3].data));

          // set title options
          setTitleOptions(sortOptions(res[4].data));
        });
      } catch (ex) {
        console.log("The data could not be loaded", ex);
      }
    };
    fetchDataFromDb();
  }, []);

  useEffect(() => {
    const gtmId = referral === "t" ? "GTM-NWFVDDH" : "GTM-WSTSRCT";

    if (process.env.NODE_ENV === "production") {
      // calculator used
      if (
        formData.deposit > 1000 &&
        (formData.income > 1000 || formData.propertyPurchasePrice > 1000) &&
        has(formData, "firstTimeBuyer") &&
        !calculatorUsedEvent
      ) {
        const tagManagerArgs = {
          gtmId: gtmId,
          dataLayer: {
            event: referral === "t" ? "calculator_used_tw" : "calculator_used",
          },
        };

        TagManager.initialize(tagManagerArgs);
        setCalculatorUsedEvent(true);
      }

      if (search && !searchUsedEvent) {
        const tagManagerArgs = {
          gtmId: gtmId,
          dataLayer: {
            event: referral === "t" ? "search_used_tw" : "search_used",
          },
        };

        TagManager.initialize(tagManagerArgs);

        setSearchUsedEvent(true);
      }
      if (contactFormSubmitted && !appointmentSubmittedEvent) {
        const tagManagerArgs = {
          gtmId: gtmId,
          dataLayer: {
            event: referral === "t" ? "appointment_tw" : "appointment",
          },
        };

        TagManager.initialize(tagManagerArgs);
        setAppointmentSubmittedEvent(true);
      }
    }
  }, [formData, search, contactFormSubmitted]);

  // if (domain === "thr")
  //   document.getElementsByTagName("title")[0].innerHTML = "Find My Threshold";

  const handleFormData = (updatedData) => {
    setFormData({ ...formData, ...updatedData });
  };

  const handleApplicationData = (updatedData) => {
    setApplicationData({ ...applicationData, ...updatedData });
  };

  // const handleAppointment = (boolean) => {
  //   setAppointment(boolean);
  // };

  const handleSearch = (boolean) => {
    setSearch(boolean);
  };

  // const handleOpenResultsPanel = (boolean) => {
  //   setOpenResultsPanel(boolean);
  // };

  const handleCalculatedPropertyPurchasePrice = (value) => {
    setCalculatedPropertyPurchasePrice(value);
  };

  const handleCalculatedLaonAmount = (value) => {
    setCalculatedLaonAmount(value);
  };

  const handleFormComplete = (boolean) => {
    setFormComplete(boolean);
  };

  const handleShowResults = (boolean) => {
    setShowResults(boolean);
  };

  const handleSetModal = (object) => {
    setModal(object);
  };

  const handleSetRates = (updatedRates) => {
    setRates(updatedRates);
  };

  const handleSetLowestRates = (updatedRates) => {
    setLowestRates(updatedRates);
  };

  const handelContactFormSubmitted = (boolean) => {
    setContactFormSubmitted(boolean);
  };

  return (
    <AppContext.Provider
      value={{
        formData: formData,
        setFormData: handleFormData,
        applicationData: applicationData,
        setApplicationData: handleApplicationData,

        // openResultsPanel: openResultsPanel,
        // setOpenResultsPanel: handleOpenResultsPanel,
        // appointment: appointment,
        // setAppointment: handleAppointment,
        search: search,
        setSearch: handleSearch,
        domain: domain,
        referral: referral,
        calculatorUsedEvent: calculatorUsedEvent,
        calculatedPropertyPurchasePrice: calculatedPropertyPurchasePrice,
        setCalculatedPropertyPurchasePrice:
          handleCalculatedPropertyPurchasePrice,
        calculatedLaonAmount: calculatedLaonAmount,
        setCalculatedLaonAmount: handleCalculatedLaonAmount,
        formComplete: formComplete,
        setFormComplete: handleFormComplete,
        showResults: showResults,
        setShowResults: handleShowResults,
        modal: modal,
        setModal: handleSetModal,
        rates: rates,
        setRates: handleSetRates,
        lowestRates: lowestRates,
        setLowestRates: handleSetLowestRates,
        propertyRegionOptions: propertyRegionOptions,
        schemeOptions: schemeOptions,
        enquiryOptions: enquiryOptions,
        titleOptions: titleOptions,
        contactFormSubmitted: contactFormSubmitted,
        setContactFormSubmitted: handelContactFormSubmitted,
      }}
    >
      <>
        {/* <AnimatedBackground /> */}

        <Header />
        {/* <Navigation /> */}
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/find-budget" element={<CalculationsPage />} />
            <Route path="/find-mortgage" element={<FindMortgagePage />} />
            <Route path="/start-mortgage" element={<ApplicationPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
        <Footer />
        <Modal modal={modal} />
      </>
    </AppContext.Provider>
  );
}

export default App;
