import percentage from "calculate-percentages";
import { property } from "lodash";

export const calculateAffordability = (
  income,
  deposit,
  buyToLet,
  propertyPurchasePrice,
  sharedOwnershipPercentage
) => {
  const resultBorrow = buyToLet ? deposit / 0.2 - deposit : income * 5.5;

  const resultPropertyPurchasePrice = buyToLet
    ? deposit / 0.2
    : +resultBorrow + +deposit;

  // const resultFirstHomesPropertyPurchasePrice =
  //   resultPropertyPurchasePrice -
  //   percentage.of(30, resultPropertyPurchasePrice);

  //const sharePrice = (propertyPurchasePrice * sharedOwnershipPercentage) / 100;

  const resultSharedOwnership =
    (propertyPurchasePrice * sharedOwnershipPercentage) / 100 - deposit;

  const resultFirstHomesPropertyPurchasePrice =
    resultPropertyPurchasePrice * 0.7;

  const borrowHelpToBuy = income * 4.5;

  const helpToBuyIncomeDeposit = +borrowHelpToBuy + +deposit;

  const propertyValueHelpToBuy = +helpToBuyIncomeDeposit / 0.8;

  const helpToBuyPercentage = 20;

  const helpToBuyRegionPriceCap = 300000;

  const resultPropertyValueHelpToBuy =
    propertyValueHelpToBuy >= helpToBuyRegionPriceCap
      ? helpToBuyRegionPriceCap
      : propertyValueHelpToBuy;

  const resultHelpToBuyLoan =
    (resultPropertyValueHelpToBuy * helpToBuyPercentage) / 100;

  const resultBorrowHelpToBuy =
    propertyValueHelpToBuy >= helpToBuyRegionPriceCap
      ? resultPropertyValueHelpToBuy - resultHelpToBuyLoan - deposit
      : borrowHelpToBuy;

  return [
    resultBorrow,
    resultPropertyPurchasePrice,
    resultFirstHomesPropertyPurchasePrice,
    resultBorrowHelpToBuy,
    resultHelpToBuyLoan,
    resultPropertyValueHelpToBuy,
    resultSharedOwnership,
  ];
};

export const calculateMortgage = (
  calcMortgageLoanAmount,
  calcMortgageTerm,
  calcInterestRate
) => {
  // Set constants and variables
  let mortgageLoanAmount = calcMortgageLoanAmount;
  let mortgageTerm = calcMortgageTerm;
  let interestRate = calcInterestRate;

  if (
    mortgageLoanAmount >= 0.01 &&
    mortgageTerm >= 0.01 &&
    interestRate >= 0.01
  ) {
    let interestTerm;
    let resultCapAndInt;
    let resultIntOnly;

    interestRate = interestRate / 100 / 12; // convert interest to a decimal fraction then in a monthly rate
    mortgageTerm = mortgageTerm * 12; // convert mortgage term from years to months
    interestTerm = Math.pow(interestRate + 1, mortgageTerm); // calculate the interest term

    resultCapAndInt = (interestRate * interestTerm) / (interestTerm - 1); // solve the equation
    resultCapAndInt = mortgageLoanAmount * resultCapAndInt;

    resultIntOnly = (interestRate * interestTerm) / interestTerm; // solve the equation interest only
    resultIntOnly = mortgageLoanAmount * resultIntOnly;

    return [resultCapAndInt, resultIntOnly];
  } else {
    return [0, 0];
  }
};

export const calculateStampDuty = (
  calcPropertyPurchasePrice,
  calcBuyToLet,
  calcFirstTimeBuyer,
  calcPropertyRegion,
  calcAdditionalPropertyPurchase
) => {
  // Set constants and variables
  const purchasePrice = calcPropertyPurchasePrice;
  const btlSecondHome = calcBuyToLet;
  const firstTimeBuyer = calcBuyToLet ? 0 : calcFirstTimeBuyer;
  const propertyRegion = parseInt(calcPropertyRegion);
  const additionalProperty = calcAdditionalPropertyPurchase;

  let band;
  let taxableSum;
  let percent;
  let taxToPay;
  let btlSecondHomePercent;
  let btlSecondHomeTaxToPay;

  let result;

  if (propertyRegion === 10 || propertyRegion === 13) {
    if (firstTimeBuyer && purchasePrice <= 625000) {
      switch (true) {
        case purchasePrice <= 425000:
          band = 1;
          taxableSum = 0;
          percent = 0;
          taxToPay = 0;
          break;
        case purchasePrice >= 425001 && purchasePrice <= 625000:
          band = 2;
          taxableSum = 425000;
          percent = 5;
          taxToPay = 0 + 0;
          break;
      }
    } else {
      switch (true) {
        case purchasePrice <= 250000:
          band = 1;
          taxableSum = 0;
          percent = 0;
          taxToPay = 0;
          btlSecondHomePercent = 5;
          btlSecondHomeTaxToPay = 0;
          break;
        case purchasePrice >= 250001 && purchasePrice <= 925000:
          band = 2;
          taxableSum = 250000;
          percent = 5;
          taxToPay = 0 + 0;
          btlSecondHomePercent = 10;
          btlSecondHomeTaxToPay = 0 + 12500;
          break;
        case purchasePrice >= 925001 && purchasePrice <= 1500000:
          band = 3;
          taxableSum = 925000;
          percent = 10;
          taxToPay = 0 + 0 + 33750;
          btlSecondHomePercent = 15;
          btlSecondHomeTaxToPay = 0 + 12500 + 67500;
          break;
        case purchasePrice >= 1500001:
          band = 4;
          taxableSum = 1500000;
          percent = 12;
          taxToPay = 0 + 0 + 33750 + 57500;
          btlSecondHomePercent = 17;
          btlSecondHomeTaxToPay = 0 + 12500 + 67500 + 86250;
          break;
      }
    }
  }

  if (propertyRegion === 12) {
    if (firstTimeBuyer && purchasePrice <= 250000) {
      switch (true) {
        case purchasePrice <= 175000:
          band = 1;
          taxableSum = 0;
          percent = 0;
          taxToPay = 0;
          break;
        case purchasePrice >= 175001 && purchasePrice <= 250000:
          band = 2;
          taxableSum = 175000;
          percent = 2;
          taxToPay = 0 + 0;
          break;
      }
    } else {
      switch (true) {
        case purchasePrice <= 145000:
          band = 1;
          taxableSum = 0;
          percent = 0;
          taxToPay = 0;
          btlSecondHomePercent = 6;
          btlSecondHomeTaxToPay = 0;
          break;
        case purchasePrice >= 145001 && purchasePrice <= 250000:
          band = 2;
          taxableSum = 145000;
          percent = 2;
          taxToPay = 0 + 0;
          btlSecondHomePercent = 8;
          btlSecondHomeTaxToPay = 0 + 8700;
          break;
        case purchasePrice >= 250001 && purchasePrice <= 325000:
          band = 3;
          taxableSum = 250000;
          percent = 5;
          taxToPay = 0 + 0 + 2100;
          btlSecondHomePercent = 11;
          btlSecondHomeTaxToPay = 0 + 8700 + 8400;
          break;
        case purchasePrice >= 325001 && purchasePrice <= 750000:
          band = 4;
          taxableSum = 325000;
          percent = 10;
          taxToPay = 0 + 0 + 2100 + 3750;
          btlSecondHomePercent = 16;
          btlSecondHomeTaxToPay = 0 + 8700 + 8400 + 8250;
          break;
        case purchasePrice >= 750001:
          band = 5;
          taxableSum = 750000;
          percent = 12;
          taxToPay = 0 + 0 + 2100 + 3750 + 42500;
          btlSecondHomePercent = 18;
          btlSecondHomeTaxToPay = 0 + 8700 + 8400 + 8250 + 68000;
          break;
      }
    }
  }

  if (propertyRegion === 11) {
    if (!btlSecondHome && !additionalProperty) {
      switch (true) {
        case purchasePrice <= 225000:
          band = 1;
          taxableSum = 0;
          percent = 0;
          taxToPay = 0;
          break;
        case purchasePrice >= 225001 && purchasePrice <= 400000:
          band = 2;
          taxableSum = 225000;
          percent = 6;
          taxToPay = 0 + 0;
          break;
        case purchasePrice >= 400001 && purchasePrice <= 750000:
          band = 3;
          taxableSum = 400000;
          percent = 7.5;
          taxToPay = 0 + 0 + 10500;
          break;
        case purchasePrice >= 750001 && purchasePrice <= 1500000:
          band = 4;
          taxableSum = 750000;
          percent = 10;
          taxToPay = 0 + 0 + 10500 + 26250;
          break;
        case purchasePrice >= 1500001:
          band = 5;
          taxableSum = 1500000;
          percent = 12;
          taxToPay = 0 + 0 + 10500 + 26250 + 75000;
          break;
      }
    } else {
      switch (true) {
        case purchasePrice <= 180000:
          band = 1;
          taxableSum = 0;
          btlSecondHomePercent = 4;
          btlSecondHomeTaxToPay = 0;
          break;
        case purchasePrice >= 180001 && purchasePrice <= 250000:
          band = 2;
          taxableSum = 180000;
          btlSecondHomePercent = 7.5;
          btlSecondHomeTaxToPay = 0 + 7200;
          break;
        case purchasePrice >= 250001 && purchasePrice <= 400000:
          band = 3;
          taxableSum = 250000;
          btlSecondHomePercent = 9;
          btlSecondHomeTaxToPay = 0 + 7200 + 5250;
          break;
        case purchasePrice >= 400001 && purchasePrice <= 750000:
          band = 4;
          taxableSum = 400000;
          btlSecondHomePercent = 11.5;
          btlSecondHomeTaxToPay = 0 + 7200 + 5250 + 13500;
          break;
        case purchasePrice >= 750001 && purchasePrice <= 1500000:
          band = 5;
          taxableSum = 750000;
          btlSecondHomePercent = 14;
          btlSecondHomeTaxToPay = 0 + 7200 + 5250 + 13500 + 40250;
          break;
        case purchasePrice >= 1500001:
          band = 6;
          taxableSum = 1500000;
          btlSecondHomePercent = 16;
          btlSecondHomeTaxToPay = 0 + 7200 + 5250 + 13500 + 40250 + 105000;
          break;
      }
    }
  }

  // Calculate the result
  if (purchasePrice) {
    if (btlSecondHome || additionalProperty) {
      let remainder = purchasePrice - taxableSum;
      result = (remainder * btlSecondHomePercent) / 100 + btlSecondHomeTaxToPay;
    } else {
      let remainder = purchasePrice - taxableSum;
      result = (remainder * percent) / 100 + taxToPay;
    }
  }

  if (propertyRegion === 12 && firstTimeBuyer && purchasePrice >= 250001) {
    result = result - 600;
  }

  return [result, (result / purchasePrice) * 100];
};
