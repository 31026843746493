import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import ResultsBadge from "./ResultsBadge";
import { ButtonSmall } from "../Buttons";

import CallToActions from "../CallToActions";

import { calculateAffordability } from "../../utils/calculators";
import { priceFormatter } from "../../utils/PriceFormatter";
import { isEmpty } from "lodash";
import settings from "../../data/settings.json";
import AppContext from "../../Context/AppContext";

function AffordabilityCalculator({
  formData,
  // calculatorResults,
  //affordabilityResults,
  calculatedLaonAmount,
  calculatedHelpToBuyLoanAmount,
  calculatedPropertyPurchasePrice,
  propertyMarketValue,
  firstHomesQualify,
  propertyRegionDetails,
  helpToBuyShare,
}) {
  const { showResults } = useContext(AppContext);

  //const [calculatorResults, setCalculatorResults] = useState({});

  // useEffect(() => {
  //   try {
  //     setCalculatorResults(affordabilityResults);
  //   } catch {}
  // }, [affordabilityResults, formData]);

  // useEffect(() => {
  //   setCalculationsData({ affordabilityResults: calculatorResults });
  // }, [formData, calculatorResults]);
  // if (!isEmpty(propertyRegionDetails))
  //   console.log("PROP REG", propertyRegionDetails);
  // move to utils at later date
  // const getHelpToBuyPercentage = (propertyRegion) => {
  //   return Number(propertyRegion) === 1 ? "40%" : "20%";
  // };

  let navigate = useNavigate();

  return (
    <>
      {formData.schemes !== "sharedOwnership" ? (
        <>
          {/* <div className="mb-8 rounded-lg border border-gray-200 bg-white p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50">
           <div className="bg-gradient-to-tl from-violet-500  via-indigo-500 to-blue-500 shadow-md shadow-purple-500/25 dark:shadow-purple-500/50 rounded-lg p-4 mb-8"> */}
          <h2 className="mb-4 text-3xl leading-none text-black dark:text-white">
            Affordability
          </h2>
          {formData.schemes !== "firstHomes" ? (
            <>
              <div className="mb-0 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900">
                <ResultsBadge
                  size="large"
                  format="price"
                  colorScheme={1}
                  result={showResults && calculatedLaonAmount}
                  label="You could borrow"
                  help={null}
                  last={formData.schemes === "helpToBuy" ? false : true}
                />

                {formData.schemes === "helpToBuy" ? (
                  <>
                    <ResultsBadge
                      size="small"
                      format="price"
                      colorScheme={1}
                      result={showResults && helpToBuyShare}
                      label="With a Help to Buy equity share (20%) of"
                      help={null}
                      last={false}
                    />

                    <ResultsBadge
                      size="large"
                      format="price"
                      colorScheme={1}
                      result={showResults && calculatedPropertyPurchasePrice}
                      label="To purchase a property valued at"
                      help={null}
                      last={true}
                    />
                  </>
                ) : null}

                {showResults &&
                formData.schemes === "helpToBuy" &&
                calculatedPropertyPurchasePrice >= 300000 ? (
                  <p className="sm:px0 mt-2 px-2 text-center text-sm text-black dark:text-white sm:text-left md:text-center lg:text-left">
                    The above figure is capped at the Help to Buy of £300,000
                    for Wales.
                    <br />
                    You may achieve a higher purchase price without using the
                    Help to Buy scheme.
                  </p>
                ) : null}

                {/* {calculatedLaonAmount ? (
                  <div className="mt-4">
                    <CallToActions />
                  </div>
                ) : null} */}
              </div>
            </>
          ) : null}
          {formData.schemes === "firstHomes" ? (
            <>
              <div className="mb-0 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900">
                <ResultsBadge
                  size="large"
                  format="price"
                  colorScheme={1}
                  // result={
                  //   firstHomesQualify
                  //     ? calculatedLaonAmount - formData.deposit
                  //     : settings.firstHomes.priceCap - formData.deposit
                  // }
                  result={showResults && calculatedLaonAmount}
                  label="You could borrow"
                  help={null}
                  last={
                    showResults && !firstHomesQualify && calculatedLaonAmount
                      ? false
                      : true
                  }
                />

                {showResults && !firstHomesQualify && calculatedLaonAmount ? (
                  <>
                    <ResultsBadge
                      size="small"
                      format="price"
                      colorScheme={1}
                      result={Math.floor(
                        showResults && calculatedPropertyPurchasePrice / 0.7
                      )}
                      label="To purchase a property with a market value of"
                      help={null}
                      last={true}
                    />
                    <p className="sm:px0 mt-2 px-2 text-center text-sm text-black dark:text-white sm:text-left md:text-center lg:text-left">
                      The above figure is capped at the regional First Homes
                      scheme limit.
                      <br />
                      You may achieve a higher purchase price without using the
                      First Homes scheme.
                    </p>
                  </>
                ) : null}

                {/* {calculatedLaonAmount && (
                  <div className="mt-4">
                    <CallToActions />
                  </div>
                )} */}
              </div>
            </>
          ) : null}
          {/* <div>
            <p className="text-xs text-black dark:text-white">
              The above results are based on the{" "}
              {formData.buyToLet ? "deposit" : "income"} figure you've provided.
              Please be aware that{" "}
              {formData.buyToLet
                ? "rental income and financial commitments will need to be"
                : "credit commitments and credit ratings have not been"}{" "}
              taken into consideration.
            </p>
          </div> 
      </div>*/}
        </>
      ) : null}
    </>
  );
}

export default AffordabilityCalculator;
