import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { has, find, orderBy, isEmpty, omit, pick, merge } from "lodash";
import moment from "moment";
// import shortid from "shortid";
import InputField from "./form/InputField";
import AppointmentPicker from "./form/AppointmentPicker";
import SelectMenu from "./form/SelectMenu";
import SingleCheckbox from "./form/SingleCheckbox";
import CheckboxGroup from "./form/CheckboxGroup";
import {
  isToday,
  renderDate,
  renderDay,
  renderTime,
} from "../utils/renderAppointmentDetails";
import { validateProperty, validateForm } from "../utils/validation";

import AppContext from "../Context/AppContext";
//import options from "../data/options.json";
import axios from "axios";
import { percentageFormatter } from "../utils/percentageFormatter";
import { priceFormatter } from "../utils/PriceFormatter";
import { leadIdGenerator } from "../utils/leadIdGenerator";
import { ButtonSmall, ButtonDefault } from "../components/Buttons";

function FormContact({
  // formData,
  // setFormData,
  product,
  appointment,
  setAppointment,
}) {
  const {
    referral,
    enquiryOptions,
    propertyRegionOptions,
    setContactFormSubmitted,
  } = useContext(AppContext);
  let navigate = useNavigate();

  const [data, setData] = useState({
    formLayout: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    natureOfEnquiry: null,
    propertyRegion: null,
    natureOfEnquiryOther: "",
    appointmentDay: null,
    appointmentGroup: null,
    appointmentTime: null,
    appointmentCallSoon: 0,
    // dayToCall: null,
    // timeToCall: null,
    storeData: 0,
    contactMethods: {},
    referral: referral ? referral : "",
  });
  const [formDataChange, setFormDataChange] = useState({});
  // const [appointment, setAppointment] = useState({});

  const [errors, setErrors] = useState({});
  //const [enquiryOptions, setEnquiryOptions] = useState([]);
  //const [propertyRegionOptions, setPropertyRegionOptions] = useState([]);
  //const [schemeOptions, setSchemeOptions] = useState([]);

  useEffect(() => {
    if (referral === "t") setData({ ...data, formLayout: 1 });
  }, [referral]);

  //useEffect(() => {
  // const fetchOptions = async () => {
  //   const sortOptions = (data) => {
  //     const filteredData = data.filter((item) => item.display === true);
  //     const sortedData = orderBy(filteredData, "order", "asc");
  //     return sortedData;
  //   };
  //   try {
  //     await Promise.all([
  //       axios.get(process.env.REACT_APP_API_URL + "/api/enquiry-options/"),
  //       axios.get(
  //         process.env.REACT_APP_API_URL + "/api/property-region-options/"
  //       ),
  //     ]).then((options) => {
  //       setEnquiryOptions(sortOptions(options[0].data));
  //       setPropertyRegionOptions(options[1].data);
  //     });
  //   } catch (ex) {
  //     console.log("The options could not be loaded", ex);
  //   }
  // };
  //  fetchOptions();
  // }, []);

  useEffect(() => {
    // VALIDATE PROPERTY
    if (!isEmpty(formDataChange))
      validateProperty(formDataChange, schemaObj, errors, setErrors);

    /// RESET VALUES ON CHANGE - move to utilities
    const { name, value } = formDataChange;
    if (name === "natureOfEnquiry") {
      if (value !== "1" && value !== "7")
        setData({ ...data, propertyRegion: null, natureOfEnquiryOther: "" });
      else if (value === "1") setData({ ...data, natureOfEnquiryOther: "" });
      else setData({ ...data, propertyRegion: null });
    }

    // if (name === "dayToCall" && isToday(value)) {
    //   if (data.timeToCall) {
    //     if (moment().hour() >= data.timeToCall && data.timeToCall !== "1")
    //       setData({ ...data, timeToCall: null });
    //   }
    // }
  }, [formDataChange]);

  const schemaObj = {
    formLayout: Joi.any(),
    firstName: Joi.string().required().messages({
      "string.empty": "Please enter your first name",
    }),
    lastName: Joi.string().required().messages({
      "string.empty": "Please enter your last name",
    }),
    email: Joi.string().email({ tlds: false }).required().messages({
      "string.empty": "Please enter your email address",
      "string.email": "Please enter a valid email address",
    }),
    phone: Joi.string().required().messages({
      "string.empty": "Please enter your phone number",
    }),
    natureOfEnquiry: Joi.when("formLayout", {
      not: 0,
      then: Joi.any(),
      otherwise: Joi.string().required().invalid("0").messages({
        "any.invalid": "Please select an option",
      }),
    }),
    propertyRegion: Joi.when("natureOfEnquiry", {
      not: "1",
      then: Joi.any(),
      otherwise: Joi.string().required().invalid("0").messages({
        "any.invalid": "Please select a region",
      }),
    }),
    natureOfEnquiryOther: Joi.when("natureOfEnquiry", {
      not: "7",
      then: Joi.any(),
      otherwise: Joi.string().required().messages({
        "string.empty": "Please enter the nature of your enquiry",
      }),
    }),
    // dayToCall: Joi.string().required().invalid("0").messages({
    //   "any.invalid": "Please select a day",
    // }),
    // timeToCall: Joi.string().required().invalid("0").messages({
    //   "any.invalid": "Please select a time slot",
    // }),
    appointmentDay: Joi.any(),
    appointmentGroup: Joi.any(),
    appointmentTime: Joi.any(),
    appointmentCallSoon: Joi.any(),
    storeData: Joi.any(),
    contactMethods: Joi.any(),
    referral: Joi.any(),
  };

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  // const handleSelectChange = ({ currentTarget }) => {
  //   const input = {
  //     name: currentTarget.name,
  //     value: parseInt(currentTarget.value),
  //   };
  //   doAfterChange(input);
  // };

  const handleCheckboxChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value) === 0 ? 1 : 0,
    };
    doAfterChange(input);
  };

  const handleCheckboxGroupChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value) === 0 ? 1 : 0,
      key: currentTarget.getAttribute("data-key"),
    };

    // FIX: won't fit into doAfterChange(input) template
    // setData({
    //   ...data,
    //   [input.name]: { ...data[input.name], [input.key]: input.value },
    // });

    doAfterChange({
      name: input.name,
      value: { ...data[input.name], [input.key]: input.value },
    });
  };

  const doAfterChange = (input) => {
    setData({ ...data, [input.name]: input.value });
    setFormDataChange(input);
  };

  // const getDays = () => {
  //   return [
  //     {
  //       _id: 0,
  //       value: 0,
  //       option: "Select",
  //       disabled: false,
  //     },
  //     {
  //       _id: 1,
  //       value: moment().format("YYYY-MM-DD"),
  //       option: "Today",
  //       disabled: moment().hour() >= 18 ? true : false,
  //     },
  //     {
  //       _id: 2,
  //       value: moment().add(1, "days").format("YYYY-MM-DD"),
  //       option: "Tomorrow",
  //       disabled: false,
  //     },
  //     {
  //       _id: 3,
  //       value: moment().add(2, "days").format("YYYY-MM-DD"),
  //       option: moment().add(2, "days").format("dddd"),
  //       disabled: false,
  //     },
  //     {
  //       _id: 4,
  //       value: moment().add(3, "days").format("YYYY-MM-DD"),
  //       option: moment().add(3, "days").format("dddd"),
  //       disabled: false,
  //     },
  //     {
  //       _id: 5,
  //       value: moment().add(4, "days").format("YYYY-MM-DD"),
  //       option: moment().add(4, "days").format("dddd"),
  //       disabled: false,
  //     },
  //     {
  //       _id: 6,
  //       value: moment().add(5, "days").format("YYYY-MM-DD"),
  //       option: moment().add(5, "days").format("dddd"),
  //       disabled: false,
  //     },
  //     {
  //       _id: 7,
  //       value: moment().add(6, "days").format("YYYY-MM-DD"),
  //       option: moment().add(6, "days").format("dddd"),
  //       disabled: false,
  //     },
  //   ];
  // };

  // const getTimes = () => {
  //   const { dayToCall } = data;

  //   return [
  //     {
  //       _id: 0,
  //       value: "0",
  //       option: "Select",
  //       disabled: false,
  //     },
  //     {
  //       _id: 1,
  //       value: "1",
  //       option: "Anytime",
  //       disabled: isToday(dayToCall) && moment().hour() >= 15 ? true : false,
  //     },
  //     {
  //       _id: 2,
  //       value: "9",
  //       option: "9am - 12pm",
  //       disabled: isToday(dayToCall) && moment().hour() >= 9 ? true : false,
  //     },
  //     {
  //       _id: 3,
  //       value: "12",
  //       option: "12pm - 3pm",
  //       disabled: isToday(dayToCall) && moment().hour() >= 12 ? true : false,
  //     },
  //     {
  //       _id: 4,
  //       value: "15",
  //       option: "3pm - 6pm",
  //       disabled: isToday(dayToCall) && moment().hour() >= 15 ? true : false,
  //     },
  //     {
  //       _id: 5,
  //       value: "18",
  //       option: "6pm - 9pm",
  //       disabled: false,
  //     },
  //   ];
  //};

  const handleSubmit = async () => {
    document.getElementById("contact").scrollIntoView();
    const leadId = leadIdGenerator();
    data.leadId = leadId;
    data.leadType = "Appointment";

    const enquiryOption = enquiryOptions.find(
      (option) => Number(data.natureOfEnquiry) === option.value
    );

    data.natureOfEnquiry =
      data.natureOfEnquiry !== null ? enquiryOption._id : null;

    const propertyRegionOption = propertyRegionOptions.find(
      (option) => Number(data.propertyRegion) === option.value
    );

    data.propertyRegion =
      data.propertyRegion !== null ? propertyRegionOption._id : null;

    // const schemeOption = schemeOptions.find(
    //   (option) => data.schemes === option.value
    // );

    // data.schemes = data.schemes !== null ? schemeOption._id : null;

    //!!!!
    // const appointmentText = `${renderDay(data.dayToCall)}, ${renderTime(
    //   data["timeToCall"]
    // )} (${renderDate(data.dayToCall)})`;
    // data.appointmentText = appointmentText;

    data.appointmentText = appointment.text; // DELETE after cascade update.
    data.appointment = appointment;

    data.product = product;

    // const renderStoreDataResult = (value) => {
    //   return value ? "Yes Please" : "No Thanks";
    // };

    // build emails
    // amend before going live
    const emailToClient = [
      {
        email: data.email,
        name: `${data.firstName} ${data.lastName}`,
      },
    ];
    const emailSubjectClient = "Your Appointment Has Been Booked";

    const emailToAdmin =
      process.env.NODE_ENV === "production"
        ? referral === "t"
          ? [{ email: "threshold.mortgages@gmail.com", name: "New Enquiry" }]
          : [
              {
                email: "newenquiry@thresholdmortgages.com",
                name: "New Enquiry",
              },
              {
                email: "daniella.stoner@thresholdmortgages.com",
                name: "Daniella Stoner ",
              },
              {
                email: "leah.newland@thresholdmortgages.com",
                name: "Leah Newland",
              },
              { email: "threshold.mortgages@gmail.com", name: "New Enquiry" },
            ]
        : [{ email: "rich.ray@hotmail.co.uk", name: "Richard" }];

    const emailSubjectAdmin = `Calculator App ${
      referral === "t" ? "(Taylor Wimpey)" : ""
    } / Book an Appointment / ${data.firstName} ${data.lastName}`;

    let emailParams;
    emailParams = pick(data, [
      "firstName",
      "lastName",
      "email",
      "phone",
      "natureOfEnquiryOther",
      "storeData",
    ]);
    emailParams.leadId = leadId;
    emailParams.referral = referral === "t" ? "Taylor Wimpey" : null;

    emailParams.natureOfEnquiry =
      referral === "t" ? null : enquiryOption.option;

    emailParams.propertyRegion =
      data.propertyRegion !== null ? propertyRegionOption.option : null;

    // emailParams.dayToCall = renderDay(data.dayToCall);
    // emailParams.timeToCall = renderTime(data.timeToCall);
    // emailParams.dateToCall = renderDate(data.dayToCall);
    // emailParams.storeData = renderStoreDataResult(data.storeData);
    // if (data.appointmentDay !== null && data.appointmentTime !== null) {
    // emailParams.dayToCall = appointment.day; /// NEXT job Format date inc developers form
    // emailParams.timeToCall = appointment.time;
    // emailParams.dateToCall = moment(appointment.date).format("Do MMMM, YYYY");
    emailParams.appointment = appointment;
    // }

    emailParams.storeData = data.storeData ? "Yes Please" : "No Thanks";

    emailParams.product = data.product;

    const officePhone = referral === "t" ? "03458 945 522" : "03300 249 115";
    emailParams.officePhone = officePhone;

    //console.log(emailParams);

    // build sms
    const formatPhone = data.phone.substring(1).replace(/\s/g, "");
    const smsRecipient = `+44${formatPhone}`;

    // const smsRecipient = `+44${trimPhone}`;

    const getSmsMsg = () => {
      const appointmentMessage =
        data.appointmentDay !== null && data.appointmentTime !== null
          ? `We have booked you an appointment for:\n\n${appointment.text}\n\nAn adviser will call you by phone to discuss your requirements.\n\n`
          : "An adviser will call you by phone as soon as possible to discuss your requirements.\n\n";

      return `Thank you for your interest in Threshold Mortgages.\n\n${appointmentMessage}For all enquiries call ${officePhone}.`;
    };

    const filteredData = omit(data, [
      "appointmentDay",
      "appointmentGroup",
      "appointmentTime",
      "appointmentCallSoon",
    ]);

    const [
      emailToClientReport,
      emailToAdminReport,
      smsToClientReport,
      sendToDefaultDataBaseReport,
      sendToCascadeDatabaseReport,
    ] = await Promise.allSettled([
      axios.post(process.env.REACT_APP_API_URL + "/api/send-email", {
        to: emailToClient,
        subject: emailSubjectClient,
        templateId: 173,
        params: emailParams,
      }),
      axios.post(process.env.REACT_APP_API_URL + "/api/send-email", {
        to: emailToAdmin,
        subject: emailSubjectAdmin,
        templateId: 174,
        params: emailParams,
      }),
      axios.post(process.env.REACT_APP_API_URL + "/api/send-sms", {
        recipient: smsRecipient,
        content: getSmsMsg(),
      }),
      axios.post(
        process.env.REACT_APP_API_URL + "/api/mortgagepro-lite-appointments",
        filteredData
      ),
      axios.post(
        process.env.REACT_APP_API_URL +
          "/api/cascade-mortgagepro-lite-appointments",
        filteredData
      ),
      // setAppointment(true),
      setContactFormSubmitted(true),

      // setFormData({ appointment: data }),
    ]);

    const getCallData = (report, target) => {
      return report.status === "fulfilled"
        ? has(report.value.data, target)
          ? report.value.data[target]
          : report.value.data.message
        : report.reason.message;
    };

    const getCallResponseStatusCode = (report) => {
      return has(report, "value.data.status")
        ? report.value.data.status
        : report.status === "fulfilled"
        ? 200
        : report.reason.response.status;
    };

    const report = {
      calls: {
        emailToClient: {
          status: emailToClientReport.status,
          data: getCallData(emailToClientReport, "messageId"),
          responseStatusCode: getCallResponseStatusCode(emailToClientReport),
        },
        emailToAdmin: {
          status: emailToAdminReport.status,
          data: getCallData(emailToAdminReport, "messageId"),
          responseStatusCode: getCallResponseStatusCode(emailToAdminReport),
        },
        smsToClient: {
          status: smsToClientReport.status,
          data: getCallData(smsToClientReport, "messageId"),
          responseStatusCode: getCallResponseStatusCode(smsToClientReport),
        },
        sendToDefaultDataBase: {
          status: sendToDefaultDataBaseReport.status,
          data: getCallData(sendToDefaultDataBaseReport, "id"),
          responseStatusCode: getCallResponseStatusCode(
            sendToDefaultDataBaseReport
          ),
        },
        sendToCascadeDatabase: {
          status: sendToCascadeDatabaseReport.status,
          data: getCallData(sendToCascadeDatabaseReport, "id"),
          responseStatusCode: getCallResponseStatusCode(
            sendToCascadeDatabaseReport
          ),
        },
      },
      leadId,
      data: emailParams,
    };

    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/api/cascade-mortgagepro-lite-appointments/check/" +
          leadId
      )
      .then((response) => {
        merge(report.calls.sendToCascadeDatabase, {
          status: sendToCascadeDatabaseReport.status,
          data: "The record has been found",
          responseStatusCode: response.status,
        });
        const emailTo = [
          {
            email: "threshold.mortgages@gmail.com",
            name: "New Enquiry",
          },
        ];
        referral === "t" && sendReport(report, emailTo);
      })
      .catch((error) => {
        merge(report.calls.sendToCascadeDatabase, {
          status: sendToCascadeDatabaseReport.status,
          data: error.message,
          responseStatusCode: error.response.status,
        });
        const emailTo = [
          // {
          //   email: "newenquiry@thresholdmortgages.com",
          //   name: "New Enquiry",
          // },
          // {
          //   email: "daniella.stoner@thresholdmortgages.com",
          //   name: "Daniella Stoner ",
          // },
          // {
          //   email: "leah.newland@thresholdmortgages.com",
          //   name: "Leah Newland",
          // },
          { email: "threshold.mortgages@gmail.com", name: "New Enquiry" },
        ];
        referral === "t" && sendReport(report, emailTo);
      });

    const sendReport = (report, emailTo) => {
      axios.post(process.env.REACT_APP_API_URL + "/api/send-email", {
        to: emailTo,
        subject: `${leadId} Lead Report`,
        templateId: 218,
        params: report,
      });

      /// deal with lead report and subscribing to mail list on backend service
    };
  };

  // Move to utils at later date
  // const getOptionSet = (requiredSet) => {
  //   const filteredOptionSet = options.filter(
  //     (o) => o.optionSet === requiredSet
  //   );
  //   return filteredOptionSet[0].options;
  // };

  // const getOption = (optionSet, value) => {
  //   const foundOption = getOptionSet(optionSet).find(
  //     (o) => Number(value) === o._id
  //   );
  //   return foundOption.option;
  // };
  ////////

  return (
    <>
      <div className="mb-16">
        <div className="mb-4 flex justify-center sm:justify-start">
          <div className="w-full max-w-sm sm:max-w-full">
            <h3 className="mb-2 text-xl font-bold text-black">
              Book an appointment
            </h3>
            <p className="mb-0 text-lg text-black">
              Select a day and time slot for a qualified adviser to call you.
            </p>
          </div>
        </div>

        <AppointmentPicker
          data={data}
          setData={setData}
          setAppointment={setAppointment}
        />
      </div>

      <div className="mb-16">
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm sm:max-w-full">
              <InputField
                name="firstName"
                label="First name"
                type="text"
                help={null}
                value={data["firstName"]}
                onChange={handleChange}
                error={errors["firstName"]}
              />
            </div>
          </div>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm sm:max-w-full">
              <InputField
                name="lastName"
                label="Last name"
                type="text"
                help={null}
                value={data["lastName"]}
                onChange={handleChange}
                error={errors["lastName"]}
              />
            </div>
          </div>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm sm:max-w-full">
              <InputField
                name="email"
                label="Email"
                type="text"
                help={null}
                value={data["email"]}
                onChange={handleChange}
                error={errors["email"]}
              />
            </div>
          </div>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm sm:max-w-full">
              <InputField
                name="phone"
                label="Phone"
                type="text"
                help={null}
                value={data["phone"]}
                onChange={handleChange}
                error={errors["phone"]}
              />
            </div>
          </div>
          {data.formLayout === 0 ? (
            <div className="mb-4 flex justify-center">
              <div className="w-full max-w-sm sm:max-w-full">
                <SelectMenu
                  name="natureOfEnquiry"
                  label="How can we help?"
                  options={enquiryOptions}
                  db={true}
                  help={null}
                  value={data["natureOfEnquiry"]}
                  onChange={handleChange}
                  error={errors["natureOfEnquiry"]}
                />
              </div>
            </div>
          ) : null}
          {data.natureOfEnquiry === "1" ? (
            <div className="mb-4 flex justify-center">
              <div className="w-full max-w-sm sm:max-w-full">
                <SelectMenu
                  name="propertyRegion"
                  label="Where is the property?"
                  options={propertyRegionOptions}
                  hiddenOptions={[10]}
                  db={true}
                  help={null}
                  value={data["propertyRegion"]}
                  onChange={handleChange}
                  error={errors["propertyRegion"]}
                />
              </div>
            </div>
          ) : null}
          {data.natureOfEnquiry === "7" ? (
            <div className="mb-4 flex justify-center">
              <div className="w-full max-w-sm sm:max-w-full">
                <InputField
                  name="natureOfEnquiryOther"
                  label="What is the nature of your enquiry?"
                  type="text"
                  help={null}
                  value={data["natureOfEnquiryOther"]}
                  onChange={handleChange}
                  error={errors["natureOfEnquiryOther"]}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <SelectMenu
            name="dayToCall"
            label="Day to call?"
            options={getDays()}
            db={false}
            help={null}
            value={data["dayToCall"]}
            onChange={handleChange}
            error={errors["dayToCall"]}
          />
        </div>
      </div>
      <div className="mb-8 flex justify-center">
        <div className="w-full max-w-sm ">
          <SelectMenu
            name="timeToCall"
            label="Time to call?"
            options={getTimes()}
            db={false}
            help={null}
            value={data["timeToCall"]}
            onChange={handleChange}
            error={errors["timeToCall"]}
          />
        </div>
      </div> */}
      <div className="mb-8 flex justify-center">
        <div className="w-full max-w-sm sm:max-w-full">
          <SingleCheckbox
            name="storeData"
            label="Tick this box if you want your details to be stored on our database, which may then be used for marketing purposes."
            help={null}
            value={data["storeData"]}
            onChange={handleCheckboxChange}
            error={errors["storeData"]}
          />
        </div>
      </div>

      <div className="mb-8 flex justify-center">
        <div className="w-full max-w-sm sm:max-w-full">
          <CheckboxGroup
            name="contactMethods"
            label={"How you would like us to contact you?"}
            options={[
              { _id: 0, option: "Email", key: "email" },
              { _id: 1, option: "Phone", key: "phone" },
              { _id: 2, option: "SMS", key: "sms" },
            ]}
            help={null}
            value={data["contactMethods"]}
            onChange={handleCheckboxGroupChange}
            error={errors["contactMethods"]}
          />
        </div>
      </div>

      <div className="mb-8 flex justify-center">
        <div className="w-full max-w-sm sm:max-w-full">
          <p className="text-sm font-medium text-black dark:text-white">
            The internet is not a secure medium and the privacy of your data
            cannot be guaranteed.
          </p>
        </div>
      </div>

      <div className="mb-0 flex justify-center">
        <div className="w-full max-w-sm sm:max-w-full">
          <div className="flex items-center">
            <span className="mr-4">
              <ButtonDefault
                color="emerald"
                label="Send"
                onClick={handleSubmit}
                disabled={validateForm(data, schemaObj)}
              />
            </span>

            <ButtonDefault
              color="white"
              label="Cancel"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
      {/* </form> */}
    </>
  );
}

export default FormContact;
