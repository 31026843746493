import { useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { ButtonDefault } from "./Buttons";
import AppContext from "../Context/AppContext";

const Header = () => {
  const { referral } = useContext(AppContext);

  let navigate = useNavigate();

  return (
    <>
      <header className="fixed z-40 mb-8 w-full bg-white shadow-md shadow-purple-500/25">
        <div className="mx-auto max-w-7xl px-4 md:px-6 lg:px-8">
          <div className="flex h-12 items-center justify-between">
            <Link to="/">
              <Logo />
            </Link>
            <div className="flex items-center gap-4">
              {referral && (
                <ButtonDefault
                  color="plain"
                  label={
                    <>
                      <span className="hidden sm:block">03458 945 522</span>
                    </>
                  }
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-4 sm:mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  onClick={() => (window.location.href = "tel:03458945522")}
                />

                // <a
                //   className="mr-8 hidden text-lg hover:underline sm:block"
                //   href="tel:03458945522"
                // >
                //   Call: 03458 945 522
                // </a>
              )}
              <ButtonDefault
                color="emerald"
                label={
                  <>
                    <span className="hidden sm:block">Book an appointment</span>
                    <span className="hidden xs:block sm:hidden">
                      Contact us
                    </span>
                  </>
                }
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 xs:mr-2 sm:hidden"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                  </svg>
                }
                onClick={() => navigate("/contact")}
              />

              {/* <button
                type="button"
                className="inline-flex items-center whitespace-nowrap rounded-full bg-emerald-500 px-2 py-2 text-xs uppercase leading-none text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:px-3 sm:text-sm"
                onClick={() => navigate("/contact")}
              >
                <span className="hidden xs:block">Book an appointment</span>
                <span className="block xs:hidden">Contact us</span>
              </button> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
