import Tooltip from "../Tooltip";
import Alert from "../Alert";

const SingleCheckbox = ({
  name,
  label,
  size = "default",
  itemsAlign = "start",
  help,
  value,
  onChange,
  error,
}) => {
  const getItemsAlignClass = () => {
    return itemsAlign === "center" ? "items-center" : "items-start";
  };

  const getCheckboxSizeClasses = () => {
    return size === "large" ? "h-8 w-8" : "h-5 w-5";
  };

  const getLabelSizeClass = () => {
    return size === "large" ? "text-lg" : "text-sm";
  };

  return (
    <>
      <div className={`flex ${getItemsAlignClass()}`}>
        <input
          type="checkbox"
          name={name}
          id={name}
          value={value ? value : 0}
          checked={value ? true : false}
          className={`${getCheckboxSizeClasses()} rounded border-gray-400 text-blueviolet focus:ring-blueviolet`}
          aria-describedby={`${name}-help`}
          onChange={onChange}
        />

        <div className="ml-4">
          <label
            htmlFor={name}
            className={`${getLabelSizeClass()} font-medium text-black dark:text-white`}
          >
            {label}
          </label>
          <span className="sr-only">{label}</span>
        </div>

        {help && <Tooltip content={help} />}
      </div>
      {error && <Alert type="danger" text={error} />}
    </>
  );
};

export default SingleCheckbox;
