import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as TrustpilotRating } from "../img/trustpilot-rating.svg";
import { ButtonLarge } from ".//Buttons";
import Title from "./../components/Title";
import AppContext from "../Context/AppContext";

const BlockAbout = () => {
  const { referral } = useContext(AppContext);

  let navigate = useNavigate();

  return (
    <>
      <div className="cur -mb-12 h-12 sm:-mb-16 sm:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-gray-100"
        >
          <path
            d="M1139,2293.461H-141s160,96,320,96,200-95.682,320-96,200,192,320,192C939,2480.781,1139,2293.461,1139,2293.461Z"
            transform="translate(141 -2293.46)"
          ></path>
        </svg>
      </div>

      <div className="bg-white px-4 pb-32 pt-32 md:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-7xl bg-white">
          <div className="mb-8">
            <Title title="About Threshold Mortgage Advice" />
          </div>

          <p className="mb-2 text-center text-xl text-black dark:text-white">
            • We will not charge you a fee for using our mortgage advice
            service.
          </p>
          <p className="mb-2 text-center text-lg text-black dark:text-white">
            • Our team are experts in providing mortgage advice for new build
            homes.
          </p>
          <p className="mb-2 text-center text-lg text-black dark:text-white">
            • We can help you find the most suitable mortgage for your
            circumstances.
          </p>
          <p className="mb-2 text-center text-lg text-black dark:text-white">
            • You can find out more about Threshold Mortgage Advice by{" "}
            <a
              className="text-emerald-500 hover:underline"
              href="https://www.thresholdmortgages.com/?utm_source=MPLTWOC22&utm_medium=web_app"
              target="_blank"
            >
              visiting our website
            </a>
            .
          </p>
          <p className="mb-2 text-center text-lg text-black dark:text-white">
            • Providing award winning mortgage advice since 2004.
          </p>
          <p className="mb-4 text-center text-lg text-black dark:text-white">
            • Open 7 days a week.
          </p>
          <div className="mb-4 flex flex-col items-center">
            <a
              className="pb-16"
              href="https://www.trustpilot.com/review/thresholdmortgages.com"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              <TrustpilotRating />
            </a>

            <ButtonLarge
              color="emerald"
              label="Speak to an expert adviser"
              onClick={() => navigate("/contact")}
            />
          </div>
          {referral && (
            <p className="text-center">
              <a
                href="tel:03458945522"
                className="mb-0 text-xl font-semibold text-black hover:underline dark:text-white"
              >
                Or call 03458 945 522
              </a>
            </p>
          )}
        </div>
      </div>

      <div className="cur -mt-12 h-12 sm:-mt-16 sm:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-gray-100"
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>
    </>
  );
};

export default BlockAbout;
