import React, { useContext, useEffect, useState, useRef } from "react";
import Joi from "joi";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";
import { round } from "lodash";
import { has, isEmpty, orderBy } from "lodash";

import InputPriceField from "../form/InputPriceField";
import Alert from "../Alert";
import ResultsBadge from "./ResultsBadge";

import CallToActions from "../CallToActions";
import { validateProperty } from "../../utils/validation";
import { calculateMortgage } from "../../utils/calculators";
import {
  getMinDepositAmount,
  getSharedOwnershipDepositAlert,
} from "../../utils/getSharedOwnershipDepositAlert";

import { priceFormatter } from "../../utils/PriceFormatter";
import { ButtonSmall } from "../Buttons";
import percentage from "calculate-percentages";
import settings from "../../data/settings.json";

import AppContext from "../../Context/AppContext";

function SharedOwnershipResults({
  formData,
  setFormData,

  calculatedLaonAmount,
  setCalculatedLaonAmount,

  calculatedPropertyPurchasePrice,
}) {
  const { showResults, setShowResults, rates, lowestRates } =
    useContext(AppContext);

  const defaultCalculatorData = {
    calcPropertyPurchasePrice: null,
    calcSharedOwnershipPercentage: null,
    calcDeposit: null,
    calcMortgageLoanAmount: null,
    calcMortgageTerm: 30,
    calcMortgageInterest: null,
    calcRentalAmount: null,
    calcRentalInterest: 2.75,
  };
  const [calculatorData, setCalculatorData] = useState(defaultCalculatorData);
  const [calculatorDataChange, setCalculatorDataChange] = useState({});
  const [calculatorResults, setCalculatorResults] = useState({
    monthlyMortgageResult: [0, 0],
    monthlyRentalResult: 0,
  });
  // const [calculatedSharePrice, setCalculatedSharePrice] = useState();
  const [minDepositAmount, setMinDepositAmount] = useState();
  const [lowestRate, setLowestRate] = useState();
  const isMounted = useRef(false);

  // const [showResults, setShowResults] = useState(false);

  // const [adjustedPropertyPurchasePrice, setAdjustedPropertyPurchasePrice] =
  //   useState({});

  // let navigate = useNavigate();

  // useEffect(() => {
  //   doAfterChange(calculatorData);
  // }, [formData, calculatorData]);

  // useEffect(() => {
  /// Validation
  // const sharePrice =
  //   (formData.propertyPurchasePrice * formData.sharedOwnershipPercentage) /
  //   100;

  // if (
  //   has(formData, "jointApplication") &&
  //   formData.propertyRegion &&
  //   formData.propertyRegion !== "0" &&
  //   // has(formData, "firstTimeBuyer") &&
  //   formData.sharedOwnershipPercentage &&
  //   //has(formData, "additionalPropertyPurchase") &&
  //   formData.propertyPurchasePrice &&
  //   formData.deposit >= getMinDepositAmount(sharePrice) &&
  //   formData.income
  // ) {
  //   if (formData.propertyToSell !== 1) {
  //     setShowResults(true);
  //     doAfterChange(calculatorData);
  //   } else {
  //     if (
  //       formData.propertyToSellValue &&
  //       formData.outstandingMortgageAmount
  //     ) {
  //       setShowResults(true);
  //       doAfterChange(calculatorData);
  //     } else {
  //       setShowResults(false);
  //       setCalculatorResults({
  //         monthlyMortgageResult: [0, 0],
  //         monthlyRentalResult: 0,
  //       });
  //     }
  //   }
  // } else {
  //   setShowResults(false);
  //   //doAfterChange(defaultCalculatorData);
  //   setCalculatorResults({
  //     monthlyMortgageResult: [0, 0],
  //     monthlyRentalResult: 0,
  //   });
  // }
  // // } else {
  // //   setShowResults(false);
  // // }

  // }, [formData, calculatorData]);

  // useEffect(() => {
  //   const sharePrice =
  //     (formData.propertyPurchasePrice * formData.sharedOwnershipPercentage) /
  //     100;

  //   setCalculatedLaonAmount(calculatedLaonAmount);
  // }, []);

  useEffect(() => {
    if (formData.schemes === "sharedOwnership") {
      if (showResults) {
        const data = { ...calculatorData };

        const sharePrice =
          (formData.propertyPurchasePrice *
            formData.sharedOwnershipPercentage) /
          100;

        // const mortgageLoanAmount = sharePrice - formData.deposit;
        // console.log("0-CLA (so)", calculatedLaonAmount);

        data.calcMortgageLoanAmount = calculatedLaonAmount;

        data.calcRentalAmount = formData.propertyPurchasePrice - sharePrice;
        data.calcPropertyPurchasePrice = formData.propertyPurchasePrice;
        data.calcSharedOwnershipPercentage = formData.sharedOwnershipPercentage;
        data.calcDeposit = formData.deposit;

        if (
          formData.deposit >= getMinDepositAmount(sharePrice) &&
          formData.schemes === "sharedOwnership"
        ) {
          //    const loanToValue = (mortgageLoanAmount / sharePrice) * 100;
          const loanToValue = (calculatedLaonAmount / sharePrice) * 100;

          // console.log("1-LTV (so)", loanToValue);
          // const getRateGroup = () => {
          //   if (!formData.sharedOwnership && !formData.buyToLet)
          //     return "residential";
          //   if (formData.sharedOwnership) return "sharedOwnership";
          //   if (formData.buyToLet) return "buyToLet";
          // };

          /// move to utils
          if (loanToValue <= 95) {
            const getRateGroup = () => {
              if (formData.schemes !== "sharedOwnership" && !formData.buyToLet)
                return "residential";
              if (formData.schemes === "sharedOwnership")
                return "sharedOwnership";
              if (formData.buyToLet) return "buyToLet";
            };

            const rateGroup = getRateGroup();

            //  console.log("2-Rate Group (so)", rateGroup);

            let filteredRates = [...rates].filter((item) => item[rateGroup]);

            filteredRates = filteredRates[0][rateGroup].filter(
              (item) => item.maxLtv >= loanToValue
            );

            const orderedRates = orderBy(filteredRates, "rate", "asc");

            const lowestFoundRate = orderedRates.find((item) => {
              return item.rate > 0;
            });

            //console.log("3-Lowest Rate mortgage (so)", lowestFoundRate);

            data.calcMortgageInterest = lowestFoundRate.rate;
            // console.log(
            //   "**data.calcMortgageInterest",
            //   data.calcMortgageInterest
            // );
          }
          //setLowestRate(lowestFoundRate.rate);
        }

        doAfterChange(data);
        setMinDepositAmount(getMinDepositAmount(sharePrice));
      } else {
        doAfterChange(defaultCalculatorData);
        setCalculatorResults({
          monthlyMortgageResult: [0, 0],
          monthlyRentalResult: 0,
        });
      }
    }
  }, [
    formData.schemes,
    formData.sharedOwnershipPercentage,
    formData.deposit,
    formData.propertyPurchasePrice,
    formData.income,
    //calculatedPropertyPurchasePrice,
    //setCalculatedLaonAmount,
    //calculatedLaonAmount,
    showResults,
  ]);

  useEffect(() => {
    const data = { ...calculatorData };

    const sharePrice =
      (data.calcPropertyPurchasePrice * data.calcSharedOwnershipPercentage) /
      100;

    data.calcMortgageLoanAmount = sharePrice - data.calcDeposit;
    //data.calcMortgageLoanAmount = calculatedLaonAmount;
    data.calcRentalAmount = data.calcPropertyPurchasePrice - sharePrice;

    if (isMounted.current) {
      doAfterChange(data);
    } else {
      isMounted.current = true;
    }

    // doAfterChange(data);

    setMinDepositAmount(getMinDepositAmount(sharePrice));
  }, [calculatorDataChange]);

  useEffect(() => {
    setFormData({
      adjustedPropertyPurchasePrice: calculatorData.calcPropertyPurchasePrice,
      adjustedSharedOwnershipPercentage:
        calculatorData.calcSharedOwnershipPercentage,
    });
  }, [
    calculatorData.calcPropertyPurchasePrice,
    calculatorData.calcSharedOwnershipPercentage,
  ]);

  const handleRangeChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value.replace(/[^0-9.-]+/g, ""),
      min: currentTarget.min,
      max: currentTarget.max,
      maxLength: currentTarget.maxLength,
    };

    if (input.min && input.max) {
      input.value =
        Number(input.value) > Number(input.max)
          ? input.max
          : input.value !== "" && Number(input.value) < Number(input.min)
          ? input.min
          : input.value;
    }

    // if (input.maxLength)
    if (input.value.length > input.maxLength)
      input.value = input.value.substr(0, input.maxLength);

    let data = { ...calculatorData };

    data[input.name] = isNaN(parseFloat(input.value))
      ? 0
      : parseFloat(input.value);

    setCalculatorDataChange(input);
    doAfterChange(data);
  };

  const doAfterChange = (data) => {
    setCalculatorData(data);
    //setAdjustedPropertyPurchasePrice(data.calcPropertyPurchasePrice);
    setCalculatorResults({
      monthlyMortgageResult: calculateMortgage(
        data.calcMortgageLoanAmount,
        data.calcMortgageTerm,
        data.calcMortgageInterest
      ),
      monthlyRentalResult: calculateMonthlyRent(
        data.calcRentalInterest,
        data.calcRentalAmount
      ),
    });
    setCalculatedLaonAmount(data.calcMortgageLoanAmount);
  };

  const calculateMonthlyRent = (interestRate, rentalShare) => {
    return Math.round(percentage.of(interestRate, rentalShare) / 12);
  };

  const handleReset = (type) => {
    const data = { ...calculatorData };

    if (type === "main") {
      data.calcPropertyPurchasePrice = formData.propertyPurchasePrice;
      data.calcSharedOwnershipPercentage = formData.sharedOwnershipPercentage;
      data.calcDeposit = formData.deposit;
    }

    if (type === "mortgage") {
      data.calcMortgageTerm = 30;
      data.calcMortgageInterest = lowestRates.sharedOwnership;
    }

    if (type === "rental") {
      data.calcRentalInterest = 2.75;
    }

    doAfterChange(data);
  };

  const { calcMortgageTerm, calcMortgageInterest, calcRentalInterest } =
    calculatorData;

  const incrementButtons = (name, increment, maxLength, min, max) => {
    const handleMinus = (name, increment) => {
      const data = { ...calculatorData };

      const incrementValue = data[name] - increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      setCalculatorDataChange({ name, value: data[name] });
      doAfterChange(data);
    };

    const handlePlus = (name, increment) => {
      const data = { ...calculatorData };

      const incrementValue = data[name] + increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      setCalculatorDataChange({ name, value: data[name] });
      doAfterChange(data);
    };

    return (
      <div className="mt-1 flex w-full justify-between">
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600  dark:focus:ring-offset-black dark:disabled:bg-gray-600/50	dark:disabled:text-white/25"
          disabled={calculatorData[name] <= min}
          onClick={() => handleMinus(name, increment)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600  dark:focus:ring-offset-black dark:disabled:bg-gray-600/50	dark:disabled:text-white/25"
          disabled={calculatorData[name] >= max}
          onClick={() => handlePlus(name, increment)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <>
      {formData.schemes === "sharedOwnership" ? (
        <div className="mb-8 rounded-lg border border-gray-200 bg-white p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50">
          <>
            <div className="pb-4">
              <h2 className="mb-4 text-3xl leading-none text-black dark:text-white">
                Shared Ownership
              </h2>

              <div className="mb-4 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900">
                <ResultsBadge
                  size="large"
                  format="price"
                  colorScheme={2}
                  result={
                    showResults &&
                    calculatorResults.monthlyMortgageResult[0] +
                      calculatorResults.monthlyRentalResult
                  }
                  label="Total monthly payments"
                  help={
                    <p className="text-md text-white">
                      Total estimated monthly costs based on mortgage and rent.
                    </p>
                  }
                  last={false}
                />
                <p className="text-xs text-black dark:text-white sm:text-left md:text-center lg:text-left">
                  Breakdown:
                </p>
                <p className="text-black dark:text-white sm:text-left md:text-center lg:text-left">
                  <span className="font-bold">
                    {/* {priceFormatter(calculatorResults.monthlyMortgageResult[0])} */}
                    <CountUp
                      end={
                        showResults
                          ? calculatorResults.monthlyMortgageResult[0]
                          : 0
                      }
                      duration={0.5}
                      separator={","}
                      prefix={"£"}
                      preserveValue={true}
                    />
                  </span>{" "}
                  Mortgage repayments{" "}
                  {showResults ? (
                    // `(${calculatorData.calcSharedOwnershipPercentage}%)`
                    <CountUp
                      end={
                        showResults
                          ? calculatorData.calcSharedOwnershipPercentage
                          : 0
                      }
                      duration={0.5}
                      prefix={"("}
                      suffix={"%)"}
                      preserveValue={true}
                    />
                  ) : null}
                </p>
                <p className="text-black dark:text-white sm:text-left md:text-center lg:text-left">
                  <span className="font-bold">
                    {/* {priceFormatter(calculatorResults.monthlyRentalResult)} */}
                    <CountUp
                      end={
                        showResults ? calculatorResults.monthlyRentalResult : 0
                      }
                      duration={0.5}
                      separator={","}
                      prefix={"£"}
                      preserveValue={true}
                    />
                  </span>{" "}
                  Rent{" "}
                  {showResults ? (
                    //  `(${100 - calculatorData.calcSharedOwnershipPercentage}%)`
                    <CountUp
                      end={
                        showResults
                          ? 100 - calculatorData.calcSharedOwnershipPercentage
                          : 0
                      }
                      duration={0.5}
                      prefix={"("}
                      suffix={"%)"}
                      preserveValue={true}
                    />
                  ) : null}
                </p>

                {/* {showResults ? (
                  <div className="mt-4">
                    <CallToActions />
                  </div>
                ) : null} */}
              </div>
              {showResults ? (
                <>
                  <div className="mb-4">
                    <label
                      htmlFor="calcSharedOwnershipPercentageNumber"
                      className="text-md mb-1 block font-medium text-black dark:text-white"
                    >
                      Adjust share percentage
                    </label>
                    <div className="flex items-center">
                      <input
                        type="range"
                        name="calcSharedOwnershipPercentage"
                        id="calcSharedOwnershipPercentageRange"
                        maxLength={3}
                        min={1}
                        max={100}
                        step={1}
                        value={
                          calculatorData.calcSharedOwnershipPercentage
                            ? calculatorData.calcSharedOwnershipPercentage
                            : ""
                        }
                        className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                        onChange={handleRangeChange}
                      />
                      <div className="text-md ml-4 block w-[8rem] bg-white font-bold text-black  dark:bg-black dark:text-white  ">
                        {calculatorData.calcSharedOwnershipPercentage}%
                      </div>
                    </div>
                    {incrementButtons(
                      "calcSharedOwnershipPercentage",
                      1,
                      3,
                      1,
                      100
                    )}
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="calcPropertyPurchasePriceRange"
                      className="text-md mb-1 block font-medium text-black dark:text-white"
                    >
                      Adjust property purchase price
                    </label>
                    <div className="flex items-center">
                      <input
                        type="range"
                        name="calcPropertyPurchasePrice"
                        id="calcPropertyPurchasePriceRange"
                        maxLength={9}
                        min={
                          formData.propertyPurchasePrice
                            ? formData.propertyPurchasePrice >= 100000
                              ? formData.propertyPurchasePrice - 100000
                              : 500
                            : 0
                        }
                        max={
                          formData.propertyPurchasePrice
                            ? Math.round(
                                (formData.propertyPurchasePrice + 100000) / 500
                              ) * 500
                            : 0
                        }
                        step={500}
                        value={
                          calculatorData.calcPropertyPurchasePrice
                            ? calculatorData.calcPropertyPurchasePrice
                            : ""
                        }
                        className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                        onChange={handleRangeChange}
                      />
                      <div className="text-md ml-4 block w-[8rem] bg-white font-bold text-black  dark:bg-black dark:text-white  ">
                        {priceFormatter(
                          calculatorData.calcPropertyPurchasePrice
                        )}
                      </div>
                    </div>
                    {incrementButtons(
                      "calcPropertyPurchasePrice",
                      500,
                      9,
                      formData.propertyPurchasePrice >= 100000
                        ? formData.propertyPurchasePrice - 100000
                        : 500,
                      Math.round(
                        (formData.propertyPurchasePrice + 100000) / 500
                      ) * 500
                    )}
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="calcDepositRange"
                      className="text-md mb-1 block font-medium text-black dark:text-white"
                    >
                      Adjust deposit
                    </label>
                    <div className="flex items-center">
                      <input
                        type="range"
                        name="calcDeposit"
                        id="calcDepositRange"
                        maxLength={9}
                        // min={
                        //   formData.deposit
                        //     ? formData.deposit >= 50000 &&
                        //       formData.deposit >= minDepositAmount
                        //       ? formData.deposit - 50000
                        //       : minDepositAmount <= 50000
                        //       ? minDepositAmount
                        //       : 500
                        //     : 0
                        // }
                        min={
                          formData.deposit
                            ? formData.deposit >= 50000
                              ? formData.deposit - 50000 >= minDepositAmount
                                ? formData.deposit - 50000
                                : minDepositAmount
                              : minDepositAmount
                            : 500
                        }
                        max={
                          formData.deposit
                            ? Math.round((formData.deposit + 50000) / 500) * 500
                            : 0
                        }
                        step={500}
                        value={
                          calculatorData.calcDeposit
                            ? calculatorData.calcDeposit
                            : ""
                        }
                        className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                        onChange={handleRangeChange}
                      />
                      <div className="text-md ml-4 block w-[8rem] bg-white font-bold text-black  dark:bg-black dark:text-white  ">
                        {priceFormatter(calculatorData.calcDeposit)}
                      </div>
                    </div>
                    {incrementButtons(
                      "calcDeposit",
                      500,
                      9,
                      formData.deposit
                        ? formData.deposit >= 50000
                          ? formData.deposit - 50000 >= minDepositAmount
                            ? formData.deposit - 50000
                            : minDepositAmount
                          : minDepositAmount
                        : 500,
                      Math.round((formData.deposit + 50000) / 500) * 500
                    )}
                  </div>

                  <div className="mb-4 flex justify-end">
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-gray-100 px-4 py-2 text-sm font-bold leading-none text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600	dark:focus:ring-offset-black"
                      onClick={() => handleReset("main")}
                    >
                      Reset
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </>

          <>
            <div className="pb-4">
              <h3 className="mb-4 text-2xl leading-none text-black dark:text-white">
                Mortgage repayments
              </h3>

              <div className="mb-4 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900">
                <ResultsBadge
                  size="small"
                  format="price"
                  colorScheme={null}
                  result={
                    showResults && calculatorResults.monthlyMortgageResult[0]
                  }
                  label={
                    <span>
                      Mortgage repayments{" "}
                      <span className="text-sm font-normal">(monthly)</span>
                    </span>
                  }
                  help={
                    <p className="text-md text-white">
                      The estimated monthly costs on repaying the mortgage.
                    </p>
                  }
                  last={false}
                />
                <p className="text-black dark:text-white sm:text-left md:text-center lg:text-left">
                  <span className="font-bold">
                    {/* {showResults
                      ? priceFormatter(calculatorData.calcMortgageLoanAmount)
                      : "£0"} */}
                    <CountUp
                      end={
                        showResults ? calculatorData.calcMortgageLoanAmount : 0
                      }
                      duration={0.5}
                      separator={","}
                      prefix={"£"}
                      preserveValue={true}
                    />
                  </span>{" "}
                  Mortgage loan amount
                  <br />
                  <span className="text-sm font-normal">
                    (based&nbsp;on share&nbsp;price)
                  </span>
                </p>
              </div>
              {showResults ? (
                <>
                  <div className="mb-4">
                    <label
                      htmlFor="calcMortgageTermNumber"
                      className="text-md mb-1 block font-medium text-black dark:text-white"
                    >
                      Mortgage term (years)
                    </label>
                    <div className="flex items-center">
                      <input
                        type="range"
                        name="calcMortgageTerm"
                        id="calcMortgageTermRange"
                        maxLength={2}
                        min={1}
                        max={40}
                        value={calcMortgageTerm}
                        className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                        onChange={handleRangeChange}
                      />
                      <input
                        type="number"
                        name="calcMortgageTerm"
                        id="calcMortgageTermNumber"
                        maxLength={2}
                        min={1}
                        max={40}
                        value={
                          Number(calcMortgageTerm) === 0
                            ? ""
                            : Number(calcMortgageTerm)
                        }
                        className="text-md ml-4 block w-[6rem] rounded-md border border-gray-400 bg-white font-bold text-black focus:border-blueviolet focus:ring-blueviolet dark:border-gray-600 dark:bg-black dark:text-white"
                        onChange={handleRangeChange}
                      />
                    </div>

                    {incrementButtons("calcMortgageTerm", 1, 2, 1, 40)}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="calcMortgageInterestNumber"
                      className="text-md mb-1 block font-medium text-black dark:text-white"
                    >
                      Interest rate (%)
                    </label>
                    <div className="flex items-center">
                      <input
                        type="range"
                        name="calcMortgageInterest"
                        id="calcMortgageInterestRange"
                        maxLength={4}
                        min={0.01}
                        max={10}
                        step={0.01}
                        value={
                          calcMortgageInterest
                            ? calcMortgageInterest
                            : lowestRates.sharedOwnership
                        }
                        className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                        onChange={handleRangeChange}
                      />
                      <input
                        type="number"
                        name="calcMortgageInterest"
                        id="calcMortgageInterestNumber"
                        maxLength={4}
                        min={0.01}
                        max={10}
                        step={0.01}
                        value={
                          Number(calcMortgageInterest)
                            ? Number(calcMortgageInterest)
                            : lowestRates.sharedOwnership
                        }
                        className="text-md ml-4 block w-[6rem] rounded-md border border-gray-400 bg-white font-bold text-black focus:border-blueviolet focus:ring-blueviolet dark:border-gray-600 dark:bg-black dark:text-white  "
                        onChange={handleRangeChange}
                      />
                    </div>
                    {incrementButtons("calcMortgageInterest", 0.1, 4, 0.01, 10)}
                  </div>

                  <div className="mb-4 flex justify-end">
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-gray-100 px-4 py-2 text-sm font-bold leading-none text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600	dark:focus:ring-offset-black"
                      onClick={() => handleReset("mortgage")}
                    >
                      Reset
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </>

          <>
            <div className="pb-0">
              <h3 className="mb-4 text-2xl leading-none text-black dark:text-white">
                Rent
              </h3>

              <div className="mb-4 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900">
                <ResultsBadge
                  size="small"
                  format="price"
                  colorScheme={null}
                  result={showResults && calculatorResults.monthlyRentalResult}
                  label="Monthly rent"
                  help={
                    <p className="text-md text-white">
                      The estimated rent on unowned equity.
                    </p>
                  }
                  last={false}
                />
                <p className="text-black dark:text-white sm:text-left md:text-center lg:text-left">
                  <span className="font-bold">
                    {/* {showResults
                      ? priceFormatter(calculatorData.calcRentalAmount)
                      : "£0"} */}
                    <CountUp
                      end={showResults ? calculatorData.calcRentalAmount : 0}
                      duration={0.5}
                      separator={","}
                      prefix={"£"}
                      preserveValue={true}
                    />
                  </span>{" "}
                  Rental share
                </p>
              </div>

              {showResults ? (
                <>
                  <div className="mb-4">
                    <label
                      htmlFor="calcRentalInterestNumber"
                      className="text-md mb-1 block font-medium text-black dark:text-white"
                    >
                      Interest rate (%)
                    </label>
                    <div className="flex items-center">
                      <input
                        type="range"
                        name="calcRentalInterest"
                        id="calcRentalInterestRange"
                        maxLength={4}
                        min={0.01}
                        max={10}
                        step={0.01}
                        value={calcRentalInterest}
                        className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                        onChange={handleRangeChange}
                      />
                      <input
                        type="number"
                        name="calcRentalInterest"
                        id="calcRentalInterestNumber"
                        maxLength={4}
                        min={0.01}
                        max={10}
                        step={0.01}
                        value={
                          Number(calcRentalInterest) === 0
                            ? ""
                            : Number(calcRentalInterest)
                        }
                        className="text-md ml-4 block w-[6rem] rounded-md border border-gray-400 bg-white font-bold text-black focus:border-blueviolet focus:ring-blueviolet dark:border-gray-600 dark:bg-black dark:text-white  "
                        onChange={handleRangeChange}
                      />
                    </div>
                    {incrementButtons("calcRentalInterest", 0.1, 4, 0.01, 10)}
                  </div>

                  <div className="mb-0 flex justify-end">
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-gray-100 px-4 py-2 text-sm font-bold leading-none text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600	dark:focus:ring-offset-black"
                      onClick={() => handleReset("rental")}
                    >
                      Reset
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </>

          {/* <div>
            <p className="text-xs text-black dark:text-white">
              The above results can only be used as a guide. For an exact
              borrowing figure please contact us and we will provide a full
              review for you.
            </p>
          </div> */}
        </div>
      ) : null}
    </>
  );
}

export default SharedOwnershipResults;
