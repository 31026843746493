import React, { useContext, useEffect, useState, Fragment } from "react";
import Logo from "../Logo";
import TrustpilotLogo from "../svg/TrustpilotLogo";
import AppContext from "../../Context/AppContext";
import TagManager from "react-gtm-module";

function AboutThreshold(props) {
  const { referral, domain } = useContext(AppContext);

  const [websiteRedirect, setWebsiteRedirect] = useState(false);
  const [websiteRedirectEvent, setWebsiteRedirectEvent] = useState(false);

  useEffect(() => {
    if (websiteRedirect && !websiteRedirectEvent) {
      //console.log("Fire tag!");
      const tagManagerArgs = {
        gtmId: "GTM-NWFVDDH",
        dataLayer: {
          event: "website_redirect_tw",
        },
      };

      TagManager.initialize(tagManagerArgs);

      setWebsiteRedirectEvent(true);
    }
  }, [websiteRedirect]);

  return (
    <Fragment>
      <div className="frost mb-8 w-full rounded-lg bg-white/50 p-8 shadow-xl shadow-purple-500/25 dark:bg-black/50 dark:shadow-purple-500/50">
        <div className="mb-4">
          <a
            href="https://www.thresholdmortgages.com/?utm_source=MPLTWOC22&utm_medium=web_app"
            target="_blank"
            onClick={() => setWebsiteRedirect(true)}
          >
            <Logo />
          </a>
        </div>

        <p className="mb-2 text-sm text-black dark:text-white">
          We will not charge you a fee for using our mortgage advice service.
        </p>
        <p className="mb-2 text-sm text-black dark:text-white">
          Our team are experts in providing mortgage advice for new build homes.
        </p>
        <p className="mb-2 text-sm text-black dark:text-white">
          We can help you find the most suitable mortgage for your
          circumstances.
        </p>
        <p className="mb-4 text-sm text-black dark:text-white">
          You can find out more about Threshold Mortgage Advice by{" "}
          <a
            className="text-emerald-500 hover:underline"
            href="https://www.thresholdmortgages.com/?utm_source=MPLTWOC22&utm_medium=web_app"
            target="_blank"
            onClick={() => setWebsiteRedirect(true)}
          >
            visiting our website
          </a>
          .
        </p>

        <p className="mb-8 text-sm italic text-black dark:text-white">
          Providing award winning mortgage advice since 2004.
        </p>

        <a
          className="mb-0"
          href="https://www.trustpilot.com/review/thresholdmortgages.com"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          <TrustpilotLogo />
        </a>
      </div>
    </Fragment>
  );
}

export default AboutThreshold;
