import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonSmall } from "./Buttons";
import AppContext from "../Context/AppContext";

const CallToActions = ({ animate = false }) => {
  const { referral } = useContext(AppContext);

  let navigate = useNavigate();

  return (
    <>
      <div className="m-0 flex flex-col sm:flex-row md:flex-col lg:flex-row">
        <div className="mb-2 flex flex-initial flex-row justify-center sm:mb-0 sm:ml-0 sm:mr-4 sm:flex-col sm:justify-start md:mx-0 md:mb-2 md:flex-row md:justify-center lg:mb-0 lg:ml-0 lg:mr-4 lg:flex-col lg:justify-start">
          <ButtonSmall
            color="emerald"
            label="Search "
            onClick={() => navigate("/find-mortgage")}
          />
        </div>
        <p className="text-center text-xs text-black dark:text-white sm:text-left md:text-center lg:text-left">
          Search for a mortgage that best suits your circumstances.
        </p>
      </div>

      <div className="mt-2 flex flex-col sm:flex-row md:flex-col lg:flex-row">
        <div className="mb-2 flex flex-initial flex-row justify-center sm:mb-0 sm:ml-0 sm:mr-4 sm:flex-col sm:justify-start md:mx-0 md:mb-2 md:flex-row md:justify-center lg:mb-0 lg:ml-0 lg:mr-4 lg:flex-col lg:justify-start">
          <ButtonSmall
            color="emerald"
            label="Book now"
            onClick={() => navigate("/contact")}
            animate={animate}
          />
        </div>
        <p className="text-center text-xs text-black dark:text-white sm:text-left md:text-center lg:text-left">
          Book an appointment to find out more about your affordability.
        </p>
      </div>

      {referral && (
        <p className="mt-2">
          <a className="text-md hover:underline" href="tel:03458945522">
            Or call 03458 945 522
          </a>
        </p>
      )}
    </>
  );
};

export default CallToActions;
