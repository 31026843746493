import React, { useContext, useEffect, useState, Fragment } from "react";
import ResultsBadge from "./ResultsBadge";
import { calculateStampDuty } from "../../utils/calculators";
import { priceFormatter } from "../../utils/PriceFormatter";
import Alert from "../Alert";

import AppContext from "../../Context/AppContext";

function StampDutyCalculator({
  formData,
  // calculationsData,
  calculatedPropertyPurchasePrice,
}) {
  const { showResults } = useContext(AppContext);

  const [calculatorResults, setCalculatorResults] = useState({});

  useEffect(() => {
    try {
      // const key = formData.helpToBuy !== 1 ? 1 : 4;

      const sharePrice =
        (formData.propertyPurchasePrice * formData.sharedOwnershipPercentage) /
        100;

      const propertyPurchasePrice =
        formData.schemes === "sharedOwnership" && formData.propertyPurchasePrice
          ? sharePrice
          : calculatedPropertyPurchasePrice;

      setCalculatorResults(
        calculateStampDuty(
          propertyPurchasePrice,
          formData.buyToLet,
          formData.firstTimeBuyer,
          formData.propertyRegion,
          formData.additionalPropertyPurchase
        )
      );
      // handleHelpText(data);
    } catch {
      return false;
    }
  }, [formData, calculatedPropertyPurchasePrice]);

  const pctFormatter = (value) => {
    if (value) return value.toFixed(2) + "%";
    else return "0.00%";
  };
  return (
    <div className="mb-8 rounded-lg border border-gray-200 bg-white p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50">
      {/* <div className="bg-gradient-to-tl from-cyan-500 via-teal-500  to-emerald-500 shadow-md shadow-purple-500/25 dark:shadow-purple-500/50 rounded-lg p-4 mb-8"> */}
      <h2 className="mb-4 text-3xl leading-none text-black dark:text-white">
        {formData.propertyRegion === "12"
          ? "Land and building transition tax"
          : formData.propertyRegion === "11"
          ? "Land transaction tax"
          : "Stamp duty"}
      </h2>

      <Fragment>
        <div className="mb-0 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900">
          <ResultsBadge
            size="large"
            format="price"
            colorScheme={3}
            result={
              showResults && calculatorResults[0] ? calculatorResults[0] : 0
            }
            label={
              formData.propertyRegion !== "11" &&
              formData.propertyRegion !== "12"
                ? "Stamp duty to pay"
                : "Tax to pay"
            }
            help={null}
            last={false}
          />

          <ResultsBadge
            size="large"
            format="percentage"
            colorScheme={3}
            result={
              showResults && calculatorResults[1] ? calculatorResults[1] : 0.0
            }
            label="Effective rate"
            help={null}
            last={true}
          />
        </div>
        {formData.schemes === "sharedOwnership" ? (
          <div className="mt-2">
            <Alert
              type="info"
              text={
                <span>
                  {" "}
                  You will be liable for stamp duty based on the purchase price
                  of your share.
                  <br />
                  Your conveyancer will confirm full details.
                </span>
              }
            />
          </div>
        ) : null}
      </Fragment>
    </div>
  );
}

export default StampDutyCalculator;
