import { useLocation } from "react-router-dom";
import Navigation from "./Navigation";

const Hero = ({ content, navigation = true }) => {
  let location = useLocation();

  const { pathname } = location;

  return (
    <>
      {navigation ? (
        <div className="relative left-0 top-0 w-full sm:absolute">
          <Navigation />
        </div>
      ) : null}

      <div
        className={`gradient-45 ${navigation ? "mt-4" : "mt-12"} flex ${
          navigation ? "h-60" : "h-96"
        } animate-gradient items-center justify-center !bg-[length:400%_400%] px-4 py-0 sm:mt-12 sm:h-96 md:px-6 lg:px-8`}
      >
        <div className="-mt-24 md:-mt-32">{content}</div>
      </div>

      <div className="cur -mt-12 h-12 sm:-mt-16 sm:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className={`h-full w-full fill-current ${
            pathname === "/" ? "text-gray-100" : "text-white"
          }`}
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>
    </>
  );
};

export default Hero;
