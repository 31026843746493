import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Joi from "joi";
import { has, isEmpty, merge, orderBy, remove } from "lodash";

import RadioGroup from "./form/RadioGroup";
//import InputField from "./form/InputField";
import InputPriceField from "./form/InputPriceField";
import SelectMenu from "./form/SelectMenu";
import Alert from "./Alert";
import { ButtonLarge } from "./Buttons";

// import {
//   getMinDepositAmount,
//   sharedOwnershipDepositAlert,
// } from "../utils/sharedOwnershipDepositAlert";
import {
  getEquity,
  getEquityAlert,
  getEquityDepositAlert,
} from "../utils/getEquity";
import { validateProperty } from "../utils/validation";
import { priceFormatter } from "../utils/PriceFormatter";
import {
  getMinDepositAmount,
  getSharedOwnershipDepositAlert,
} from "../utils/getSharedOwnershipDepositAlert";

import AppContext from "../Context/AppContext";
import options from "../data/options.json";
import settings from "../data/settings.json";

function FormMain(props) {
  // let navigate = useNavigate();

  const {
    formData,
    setFormData,
    calculatedPropertyPurchasePrice,
    referral,
    showResults,
    setShowResults,
    formComplete,
    propertyRegionOptions,
    schemeOptions: originalSchemeData,
  } = useContext(AppContext);

  // const defaultSchemeOptions = [
  //   { _id: 0, value: "firstHomes", option: "First Homes" },
  //   { _id: 1, value: "sharedOwnership", option: "Shared Ownership" },
  //   { _id: 2, value: "helpToBuy", option: "Help to Buy (Wales)" },
  // ];

  const [formDataChange, setFormDataChange] = useState({});
  const [errors, setErrors] = useState({});
  const [alerts, setAlerts] = useState({});
  const [resetDepositField, setResetDepositField] = useState(false);

  // const [enquiryOptions, setEnquiryOptions] = useState([]);
  //const [propertyRegionOptions, setPropertyRegionOptions] = useState([]);
  const [defaultSchemeOptions, setDefaultSchemeOptions] = useState([]);
  const [schemeOptions, setSchemeOptions] = useState([]);
  const [hideSharedOwnership, setHideSharedOwnership] = useState(false);

  // useEffect(() => {
  //   const fetchOptions = async () => {
  //     const sortOptions = (formData) => {
  //       const filteredData = formData.filter((item) => item.display === true);
  //       const sortedData = orderBy(filteredData, "order", "asc");

  //       return sortedData;
  //     };

  //     try {
  //       await Promise.all([
  //         axios.get(process.env.REACT_APP_API_URL + "/api/enquiry-options/"),
  //         axios.get(
  //           process.env.REACT_APP_API_URL + "/api/property-region-options/"
  //         ),
  //         axios.get(process.env.REACT_APP_API_URL + "/api/scheme-options/"),
  //       ]).then((options) => {
  //         setEnquiryOptions(sortOptions(options[0].data));
  //         setPropertyRegionOptions(options[1].data);

  //         const updatedSchemes = merge(options[2].data, optionsTooltip);
  //         setDefaultSchemeOptions(updatedSchemes);

  //         if (formData.propertyRegion !== "11") {
  //           const schemeOptionsClone = [...updatedSchemes];
  //           remove(schemeOptionsClone, { value: "helpToBuy" });
  //           setSchemeOptions(schemeOptionsClone);
  //         }
  //       });
  //     } catch (ex) {
  //       console.log("The options could not be loaded", ex);
  //     }
  //   };
  //   fetchOptions();

  // const options = [...defaultSchemeOptions];
  // remove(options, { _id: 2 });
  // setSchemeOptions(options);
  //}, []);

  // useEffect(() => {
  //   referral === "t" && setHideSharedOwnership(true);
  // }, []);

  useEffect(() => {
    setResetDepositField(false);
    setFormData({ deposit: null });
    setErrors({ deposit: null });
  }, [resetDepositField]);

  useEffect(() => {
    const updatedSchemes = merge(originalSchemeData, optionsTooltip);
    setDefaultSchemeOptions(updatedSchemes);

    const schemeOptionsClone = [...updatedSchemes];
    formData.propertyRegion !== "11" &&
      remove(schemeOptionsClone, { value: "helpToBuy" });

    referral === "t" &&
      remove(schemeOptionsClone, { value: "sharedOwnership" });

    setSchemeOptions(schemeOptionsClone);
  }, []);

  useEffect(() => {
    /// VALIDATE PROPERTY - move to utilities

    if (!isEmpty(formDataChange))
      validateProperty(formDataChange, schemaObj, errors, setErrors);

    // EQUITY AND DEPOSIT ALERTS
    const equity = getEquity(
      formData.propertyToSellValue,
      formData.outstandingMortgageAmount
    );

    const equityAlert = getEquityAlert(
      formData.propertyToSellValue,
      formData.outstandingMortgageAmount
    );
    const equityDepositAlert = getEquityDepositAlert(
      formData.propertyToSellValue,
      formData.outstandingMortgageAmount
    );

    const sharedOwnershipDepositAlert = (deposit) => {
      const sharePrice =
        (formData.propertyPurchasePrice * formData.sharedOwnershipPercentage) /
        100;

      return deposit < getMinDepositAmount(sharePrice)
        ? getSharedOwnershipDepositAlert("danger", sharePrice)
        : null;
    };

    /// RESET VALUES ON CHANGE - move to utilities
    const { name, value } = formDataChange;

    if (name === "propertyRegion") {
      const options = [...defaultSchemeOptions];

      referral === "t" && remove(options, { value: "sharedOwnership" });

      if (value !== "11") {
        if (formData.firstTimeBuyer) {
          //remove(options, { _id: 2 });
          remove(options, { value: "helpToBuy" });

          setSchemeOptions(options);
        } else {
          remove(options, (item) => {
            return item.value !== "sharedOwnership";
          });

          setSchemeOptions(options);
        }

        if (formData.schemes !== "helpToBuy") {
          setFormData({
            firstHomesGreaterLondon: value !== "10" ? 0 : null,
          });
        } else {
          setFormData({
            schemes: null,
            firstHomesGreaterLondon: value !== "10" ? 0 : null,
          });
        }
      } else {
        if (formData.firstTimeBuyer) {
          setSchemeOptions(options);
        } else {
          remove(options, (item) => {
            return item.value !== "sharedOwnership";
          });

          setSchemeOptions(options);
        }
        setFormData({
          firstHomesGreaterLondon: value !== "10" ? 0 : null,
        });
      }

      // if (value !== "10") {
      //   setFormData({ firstHomesGreaterLondon: 0 });
      // } else {
      //   setFormData({ firstHomesGreaterLondon: null });
      // }
    }

    if (name === "firstTimeBuyer") {
      const options = [...defaultSchemeOptions];

      referral === "t" && remove(options, { value: "sharedOwnership" });

      if (value === 0) {
        // remove(options, (item) => {
        //   return item._id !== 1;
        // });
        remove(options, (item) => {
          return item.value !== "sharedOwnership";
        });

        setSchemeOptions(options);

        if (referral !== "t") {
          setFormData({
            // helpToBuy: null,
            // firstHomes: null,
            schemes:
              formData.schemes === "sharedOwnership" ? "sharedOwnership" : null,
            firstHomesGreaterLondon: null,
          });
        } else {
          setHideSharedOwnership(true);
          setFormData({
            scheme: 0,
            schemes: null,
            firstHomesGreaterLondon: null,
          });
        }
      } else {
        if (formData.propertyRegion !== "11") {
          // remove(options, { _id: 2 });
          remove(options, { value: "helpToBuy" });

          setSchemeOptions(options);
        } else {
          setSchemeOptions(options);
          //setSchemeOptions(defaultSchemeOptions);
        }

        if (referral !== "t") {
          setFormData({
            additionalPropertyPurchase: null,
            propertyToSell: null,
            propertyToSellValue: null,
          });
        } else {
          setHideSharedOwnership(false);
          setFormData({
            scheme: null,
            additionalPropertyPurchase: null,
            propertyToSell: null,
            propertyToSellValue: null,
          });
        }

        setAlerts({
          ...alerts,
          deposit:
            formData.schemes === "sharedOwnership"
              ? sharedOwnershipDepositAlert(equity)
              : null,
          equityDeposit: null,
        });
      }
    }
    // Remove if statement ?
    // if (name === "schemes" && value === "firstHomes") {
    //   // setFormData N/A
    //   setFormData({
    //     buyToLet: null,
    //     sharedOwnership: null,
    //   });
    // }

    if (name === "scheme") {
      if (value === 1) setFormData({ buyToLet: null });
      else {
        setFormData({
          schemes: null,
          firstHomesGreaterLondon: null,
          propertyPurchasePrice: null,
        });
        setAlerts({ ...alerts, deposit: null });
      }
    }

    if (name === "schemes") {
      if (value === "firstHomes") {
        if (formData.propertyRegion === "10")
          setFormData({ firstHomesGreaterLondon: null });
        else {
          setFormData({ firstHomesGreaterLondon: 0 });
        }

        setAlerts({ ...alerts, deposit: null, income: null });
      }

      if (value === "sharedOwnership") {
        setFormData({
          buyToLet: null,
          sharedOwnershipPercentage: 40,
          firstHomesGreaterLondon: 0,
        });

        setAlerts({ ...alerts, income: null });
      }

      if (value === "helpToBuy") {
        setFormData({
          firstHomesGreaterLondon: 0,
          propertyPurchasePrice: null,
        });

        setAlerts({ ...alerts, deposit: null, income: null });
      }
    }

    if (name === "buyToLet" && value === 1) {
      setFormData({
        // scheme: null,
        // schemes: null,
        //  firstHomes: null,
        firstHomesGreaterLondon: null,
        // sharedOwnership: null,
        // propertyPurchasePrice: null,
        additionalPropertyPurchase: null,
        // propertyToSell: null,
        // propertyToSellValue: null,
        // outstandingMortgageAmount: null,
      });
    }

    if (name === "propertyToSell") {
      // if (formData.propertyToSellValue || formData.outstandingMortgageAmount) {
      //   setResetDepositField(true);
      // }
      if (value === 0) {
        setFormData({
          propertyToSellValue: null,
          outstandingMortgageAmount: null,
        });
        setAlerts({ ...alerts, equity: null, equityDeposit: null });
      } else {
        if (formData.deposit < 1) setResetDepositField(true);
      }
    }

    if (
      name === "propertyToSellValue" ||
      name === "outstandingMortgageAmount"
    ) {
      if (formData.propertyToSellValue && formData.outstandingMortgageAmount) {
        if (equity !== 0) {
          setFormData({ deposit: equity });
          setAlerts({
            ...alerts,
            outstandingMortgageAmount: null,

            equity: equityAlert,
            deposit: sharedOwnershipDepositAlert(equity),
            equityDeposit: equityDepositAlert,
          });
        } else {
          setResetDepositField(true);
          setAlerts({
            ...alerts,
            outstandingMortgageAmount: null,

            equity: equityAlert,
            deposit: null,
            equityDeposit: null,
          });
        }
      } else {
        if (name === "outstandingMortgageAmount") {
          const inputValue = document.getElementById(
            "outstandingMortgageAmount"
          ).value;

          if (!inputValue) {
            setResetDepositField(true);

            setAlerts({
              ...alerts,
              outstandingMortgageAmount: [
                "danger",
                <span>
                  Please enter the amount outstanding on your current mortgage
                </span>,
              ],
              equity: null,
              deposit: null,
              equityDeposit: null,
            });
            setFormData({ outstandingMortgageAmount: null });
          } else {
            setAlerts({
              ...alerts,
              outstandingMortgageAmount: null,
              equity: null,
              deposit: null,
              equityDeposit: null,
            });
          }
        }
      }
    }

    if (
      name === "sharedOwnershipPercentage" ||
      name === "propertyPurchasePrice" ||
      name === "deposit"
    ) {
      //setDepositAlerts();
      setAlerts({
        ...alerts,
        deposit: sharedOwnershipDepositAlert(formData.deposit),
        equityDeposit: formData.deposit === equity ? equityDepositAlert : null,
      });
      !formData.deposit &&
        setAlerts({ ...alerts, deposit: null, equityDeposit: null });
    }

    if (
      name === "income" ||
      // name === "firstHomes" ||
      (name === "schemes" && value === "firstHomes") ||
      name === "firstHomesGreaterLondon"
    ) {
      if (
        //formData.firstHomes &&
        formData.schemes === "firstHomes" &&
        formData.firstHomesGreaterLondon !== null &&
        formData.income > getFirstHomesIncomeCap()
      ) {
        setAlerts({ ...alerts, deposit: null, income: getIncomeAlert() });
        setFormData({ income: getFirstHomesIncomeCap() });
      } else {
        setAlerts({ ...alerts, deposit: null, income: null });
      }
    }
  }, [
    formDataChange,
    calculatedPropertyPurchasePrice,
    // formData.sharedOwnershipPercentage,
  ]);

  const schemaObj = {
    jointApplication: Joi.number().required(),
    firstTimeBuyer: Joi.number().required(),
    scheme: Joi.number().required(),
    schemes: Joi.string().required(),
    buyToLet: Joi.number().required(),
    sharedOwnership: Joi.number().required(),
    sharedOwnershipPercentage: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please enter the the share percentage",
    }),
    // propertyPurchasePriceKnown: Joi.number().required(),
    // propertyPurchasePrice: Joi.number().required().invalid(0).messages({
    //   "any.invalid": "Please enter the property purchase price",
    // }),
    propertyRegion: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please select a region",
    }),

    additionalPropertyPurchase: Joi.number().required(),
    propertyToSell: Joi.number().required(),
    propertyToSellValue: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please enter the value of the property you're selling",
    }),
    outstandingMortgageAmount: Joi.number().required(),
    propertyPurchasePrice: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please enter the property purchase price",
    }),
    deposit: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please enter the deposit amount",
    }),
    helpToBuy: Joi.number().required(),
    firstHomes: Joi.number().required(),
    firstHomesGreaterLondon: Joi.number().required(),
    income: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please enter the total income",
    }),
  };

  const optionsTooltip = [
    {
      value: "sharedOwnership",
      content: (
        <p className="text-md text-white">
          Shared Ownership gives those that do not currently own a home the
          opportunity to purchase a share in a new build property.
          <br />
          <a
            type="button"
            href="https://www.gov.uk/shared-ownership-scheme"
            target="_blank"
            className="text-indigo-500 hover:underline hover:underline-offset-2"
          >
            Shared ownership info
          </a>
        </p>
      ),
    },
    {
      value: "firstHomes",
      content: (
        <p className="text-md text-white">
          First Homes is a new scheme designed to help local first time buyers
          onto the property ladder. Only available for new build homes, please
          check with your local development.
          <br />
          <a
            type="button"
            href="https://www.ownyourhome.gov.uk/scheme/first-homes/"
            target="_blank"
            className="text-indigo-500 hover:underline hover:underline-offset-2"
          >
            First Homes scheme information
          </a>
        </p>
      ),
    },
    {
      value: "helpToBuy",
      content: (
        <p className="text-md text-white">
          You can get an equity loan towards the cost of buying a new build home
          as a first time buyer.
          <br />
          <a
            type="button"
            href="https://www.gov.uk/help-to-buy-equity-loan"
            target="_blank"
            className="text-indigo-500 hover:underline hover:underline-offset-2"
          >
            Help to Buy scheme information
          </a>
        </p>
      ),
    },
  ];

  const getFirstHomesIncomeCap = () => {
    return formData.firstHomesGreaterLondon
      ? settings.firstHomes.incomeCapLondon
      : settings.firstHomes.incomeCap;
  };

  const getIncomeAlert = () => {
    return [
      "warning",
      <span>
        Your income is too high for the{" "}
        <span className="font-bold">First Homes</span> scheme. The maximum
        income allowed is{" "}
        <span className="font-bold">
          {priceFormatter(getFirstHomesIncomeCap())}
        </span>{" "}
        for a property located{" "}
        {formData.firstHomesGreaterLondon
          ? "in Greater London"
          : "outside Greater London"}
      </span>,
    ];
  };

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const handleRangeChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value.replace(/[^0-9.-]+/g, ""),
      min: currentTarget.min,
      max: currentTarget.max,
      maxLength: currentTarget.maxLength,
    };

    if (input.min && input.max) {
      input.value =
        Number(input.value) > Number(input.max)
          ? input.max
          : input.value !== "" && Number(input.value) < Number(input.min)
          ? input.min
          : input.value;
    }

    // if (input.maxLength)
    if (input.value.length > input.maxLength)
      input.value = input.value.substr(0, input.maxLength);

    // let data = { ...calculatorData };

    input.value = isNaN(parseFloat(input.value)) ? 0 : parseFloat(input.value);

    // setCalculatorData(data);
    // setCalculatorResults(
    //   calculateMortgage(
    //     data.calcMortgageLoanAmount,
    //     data.calcMortgageTerm,
    //     data.calcInterestRate
    //   )
    // );
    doAfterChange(input);
  };

  const handleRadioChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value),
    };
    // setFormData({ [input.name]: input.value });
    // onFormDataChange(input);
    doAfterChange(input);
  };

  const handlePriceChange = ({ currentTarget }) => {
    const input = {
      value: Number(currentTarget.value.replace(/[^0-9.-]+/g, "")),
      name: currentTarget.name,
    };
    // setFormData({ [input.name]: input.value });
    // onFormDataChange(input);
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setFormData({ ...formData, [input.name]: input.value });
    setFormDataChange(input);
  };

  const incrementButtons = (name, increment, maxLength, min, max) => {
    const handleMinus = (name, increment, maxLength) => {
      const data = { ...formData };

      const incrementValue = data[name] - increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      setFormData(data);
      doAfterChange({ name, value: data[name] });
    };
    const handlePlus = (name, increment) => {
      const data = { ...formData };

      const incrementValue = data[name] + increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      setFormData(data);
      doAfterChange({ name, value: data[name] });
    };

    return (
      <div className="mt-1 flex w-full justify-between">
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700  dark:focus:ring-gray-600 dark:focus:ring-offset-black	dark:disabled:bg-gray-600/50 dark:disabled:text-white/25"
          disabled={formData[name] <= min}
          onClick={() => handleMinus(name, increment, maxLength)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600  dark:focus:ring-offset-black dark:disabled:bg-gray-600/50	dark:disabled:text-white/25"
          disabled={formData[name] >= max}
          onClick={() => handlePlus(name, increment, maxLength)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  };

  // Move to utils at later date
  // const getOptionSet = (requiredSet) => {
  //   const filteredOptionSet = options.filter(
  //     (o) => o.optionSet === requiredSet
  //   );
  //   return filteredOptionSet[0].options;
  // };

  return (
    <div className="frost relative z-10 -mt-24 mb-8 w-full rounded-lg border border-gray-200 bg-white p-4 pb-0 shadow-xl shadow-purple-500/25 sm:-mt-32">
      <p className="mb-4 text-lg">Enter your details below to get started.</p>
      <div className="mb-8">
        <RadioGroup
          name="jointApplication"
          label="Is this a single or joint purchase?"
          options={[
            { _id: 0, value: 0, option: "Single" },
            { _id: 1, value: 1, option: "Joint" },
          ]}
          toolTip={null}
          value={formData["jointApplication"]}
          onChange={handleRadioChange}
          error={errors["jointApplication"]}
        />
      </div>
      <div className="mb-8">
        <SelectMenu
          name="propertyRegion"
          label="Where is the property?"
          options={propertyRegionOptions}
          hiddenOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
          db={true}
          help={null}
          value={formData["propertyRegion"]}
          onChange={handleChange}
          error={errors["propertyRegion"]}
        />
      </div>
      <div className="mb-8">
        <RadioGroup
          name="firstTimeBuyer"
          label="Are you a first time buyer?"
          options={[
            { _id: 0, value: 1, option: "Yes" },
            { _id: 1, value: 0, option: "No" },
          ]}
          toolTip={null}
          value={formData["firstTimeBuyer"]}
          onChange={handleRadioChange}
          error={errors["firstTimeBuyer"]}
        />
      </div>
      {!hideSharedOwnership ? (
        <div className="mb-8">
          <RadioGroup
            name="scheme"
            label={
              <span>
                Will you be using an affordable home ownership scheme?
                <>
                  <br />
                  {referral !== "t" ? (
                    <span className="text-sm">
                      (For example, Shared Ownership
                      {formData.firstTimeBuyer ? ", First Homes" : ""}
                      {formData.firstTimeBuyer &&
                      formData.propertyRegion === "11"
                        ? ", Help to Buy."
                        : "."}
                      )
                    </span>
                  ) : (
                    <span className="text-sm">
                      (For example, First Homes
                      {formData.firstTimeBuyer &&
                      formData.propertyRegion === "11"
                        ? ", Help to Buy."
                        : "."}
                      )
                    </span>
                  )}
                </>
              </span>
            }
            options={[
              { _id: 0, value: 1, option: "Yes" },
              { _id: 1, value: 0, option: "No" },
            ]}
            toolTip={null}
            value={formData["scheme"]}
            onChange={handleRadioChange}
            error={errors["scheme"]}
          />
        </div>
      ) : null}

      {formData.scheme === 1 && !hideSharedOwnership ? (
        <div className="mb-8">
          <RadioGroup
            name="schemes"
            label="Which scheme will you be using?"
            options={schemeOptions}
            layout="col"
            toolTip={null}
            value={formData["schemes"]}
            onChange={handleChange}
            error={errors["schemes"]}
          />
        </div>
      ) : null}

      {formData.scheme === 0 ? (
        <div className="mb-8">
          <RadioGroup
            name="buyToLet"
            label="Are you purchasing a buy to let property?"
            options={[
              { _id: 0, value: 1, option: "Yes" },
              { _id: 1, value: 0, option: "No" },
            ]}
            toolTip={null}
            value={formData["buyToLet"]}
            onChange={handleRadioChange}
            error={errors["buyToLet"]}
          />
          {formData.buyToLet === 1 ? (
            <div className="mt-1">
              <Alert
                type="info"
                text="The rental income you'll receive may change the deposit required for your mortgage"
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {/* {formData.propertyRegion === "11" ? ( //&&
        // formData.firstTimeBuyer === 1 &&
        // formData.buyToLet !== 1
        <div className="mb-8 flex flex-col">
          <RadioGroup
            name="helpToBuy"
            label={
              <span className="text-red-500">
                Are you interested in the 'Help to Buy' scheme?
              </span>
            }
            options={[
              { _id: 0, value: 1, option: "Yes" },
              { _id: 1, value: 0, option: "No" },
            ]}
            help={
              <p>
                You can get an equity loan towards the cost of buying a new
                build home as a first time buyer.
              </p>
            }
            value={formData["helpToBuy"]}
            onChange={handleRadioChange}
            error={errors["helpToBuy"]}
          />
          <a
            type="button"
            href="https://www.gov.uk/help-to-buy-equity-loan"
            target="_blank"
            className="self-end text-sm text-indigo-500 hover:underline hover:underline-offset-2"
          >
            Help to Buy scheme info
          </a>
        </div>
      ) : null} */}
      {/* {formData.firstTimeBuyer === 1 &&
      formData.buyToLet !== 1 && */}
      {/* {formData.schemes !== "sharedOwnership" ? (
        <div className="mb-8 flex flex-col">
          <RadioGroup
            name="firstHomes"
            label={
              <span className="text-red-500">
                Will you be using the 'First Homes' scheme?
              </span>
            }
            options={[
              { _id: 0, value: 1, option: "Yes" },
              { _id: 1, value: 0, option: "No" },
            ]}
            help={
              <p>
                First Homes is a new scheme designed to help local first time
                buyers onto the property ladder. Only available for new build
                homes, please check with your local development.
              </p>
            }
            value={formData["firstHomes"]}
            onChange={handleRadioChange}
            error={errors["firstHomes"]}
          />
          <p className="self-end text-sm text-black dark:text-white">
            Development specific.{" "}
            <a
              type="button"
              href="https://www.ownyourhome.gov.uk/scheme/first-homes/"
              target="_blank"
              className="text-indigo-500 hover:underline hover:underline-offset-2"
            >
              First Homes scheme info
            </a>
          </p>
        </div>
      ) : null} */}
      {formData.firstTimeBuyer === 1 &&
      formData.scheme === 1 &&
      formData.schemes === "firstHomes" &&
      formData.propertyRegion === "10" ? (
        <div className="mb-8 flex flex-col">
          <RadioGroup
            name="firstHomesGreaterLondon"
            label="Is the property in Greater London?"
            options={[
              { _id: 0, value: 1, option: "Yes" },
              { _id: 1, value: 0, option: "No" },
            ]}
            toolTip={null}
            value={formData["firstHomesGreaterLondon"]}
            onChange={handleRadioChange}
            error={errors["firstHomesGreaterLondon"]}
          />
        </div>
      ) : null}
      {/* {formData.helpToBuy === 1 ? (
        <div className="mb-8">
          <SelectMenu
            name="propertyRegion"
            label="Where is the property?"
            options={getOptionSet("propertyRegions")}
            db={false}
            help={null}
            value={formData["propertyRegion"]}
            onChange={handleChange}
            error={errors["propertyRegion"]}
          />
        </div>
      ) : null} */}
      {/* {
        // formData.buyToLet !== 1 && formData.firstHomes !== 1 &&
        !referral ? (
          <div className="mb-8 flex flex-col">
            <RadioGroup
              name="sharedOwnership"
              label={
                <span className="text-red-500">
                  Will you be using a shared ownership scheme?
                </span>
              }
              options={[
                { _id: 0, value: 1, option: "Yes" },
                { _id: 1, value: 0, option: "No" },
              ]}
              toolTip={null}
              value={formData["sharedOwnership"]}
              onChange={handleRadioChange}
              error={errors["sharedOwnership"]}
            />
            <a
              type="button"
              href="https://www.gov.uk/shared-ownership-scheme"
              target="_blank"
              className="self-end text-sm text-indigo-500 hover:underline hover:underline-offset-2"
            >
              Shared ownership info
            </a>
          </div>
        ) : null
      } */}
      {formData.schemes === "sharedOwnership" ? (
        <>
          <div className="mb-4">
            <label
              htmlFor="sharedOwnershipPercentageRange"
              className="text-md mb-1 block font-medium text-black dark:text-white"
            >
              What is the share percentage? (%)
            </label>
            <div className="flex items-center">
              <input
                type="range"
                name="sharedOwnershipPercentage"
                id="sharedOwnershipPercentageRange"
                maxLength="3"
                min="1"
                max="100"
                step="1"
                value={
                  has(formData, "sharedOwnershipPercentage")
                    ? formData.sharedOwnershipPercentage
                    : ""
                }
                className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                onChange={handleRangeChange}
              />
              <input
                type="number"
                name="sharedOwnershipPercentage"
                id="sharedOwnershipPercentageNumber"
                maxLength={3}
                min={1}
                max={100}
                step={1}
                value={
                  has(formData, "sharedOwnershipPercentage")
                    ? formData.sharedOwnershipPercentage === 0
                      ? ""
                      : formData.sharedOwnershipPercentage
                    : ""
                }
                className="text-md ml-4 block w-[6rem] rounded-md border border-gray-400 bg-white font-bold text-black focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-black dark:text-white  "
                onChange={handleRangeChange}
              />
            </div>
            {incrementButtons("sharedOwnershipPercentage", 1, 3, 1, 100)}
            {errors.sharedOwnershipPercentage && (
              <div className="mt-1">
                <Alert type="danger" text={errors.sharedOwnershipPercentage} />
              </div>
            )}
          </div>
        </>
      ) : null}
      {formData.firstTimeBuyer === 0 && formData.buyToLet === 0 ? (
        <div className="mb-8">
          <RadioGroup
            name="additionalPropertyPurchase"
            label="is this an additional property purchase?"
            options={[
              { _id: 0, value: 1, option: "Yes" },
              { _id: 1, value: 0, option: "No" },
            ]}
            toolTip={null}
            value={formData["additionalPropertyPurchase"]}
            onChange={handleRadioChange}
            error={errors["additionalPropertyPurchase"]}
          />
        </div>
      ) : null}
      {formData.firstTimeBuyer === 0 ? (
        <div className="mb-8">
          <RadioGroup
            name="propertyToSell"
            label="Do you have a property to sell?"
            options={[
              { _id: 0, value: 1, option: "Yes" },
              { _id: 1, value: 0, option: "No" },
            ]}
            toolTip={null}
            value={formData["propertyToSell"]}
            onChange={handleRadioChange}
            error={errors["propertyToSell"]}
          />
        </div>
      ) : null}
      {formData.firstTimeBuyer === 0 && formData.propertyToSell === 1 ? (
        <div className="mb-8 flex flex-col">
          <InputPriceField
            name="propertyToSellValue"
            label="What is the value of the property you're selling?"
            help={null}
            value={formData["propertyToSellValue"]}
            onChange={handlePriceChange}
            error={errors["propertyToSellValue"]}
          />
          <a
            type="button"
            href="https://www.zoopla.co.uk/"
            target="_blank"
            rel="noopener nofollow noreferrer"
            className="self-end text-sm text-indigo-500 hover:underline hover:underline-offset-2 "
          >
            Find the value of your home
          </a>
        </div>
      ) : null}
      {formData.firstTimeBuyer === 0 && formData.propertyToSell === 1 ? (
        <div className="mb-8">
          <InputPriceField
            id="outstandingMortgageAmount"
            name="outstandingMortgageAmount"
            label="How much is outstanding on your current mortgage?"
            toolTip={null}
            value={formData["outstandingMortgageAmount"]}
            acceptZeroValue={true}
            onChange={handlePriceChange}
            error={errors["outstandingMortgageAmount"]}
            alert={
              alerts.outstandingMortgageAmount ||
              getEquity(
                formData.propertyToSellValue,
                formData.outstandingMortgageAmount
              ) < 0
            }
          />
          {alerts.outstandingMortgageAmount && (
            <div className="mt-1">
              <Alert
                type={alerts.outstandingMortgageAmount[0]}
                text={alerts.outstandingMortgageAmount[1]}
              />
            </div>
          )}

          {alerts.equity && (
            <div className="mt-1">
              <Alert type={alerts.equity[0]} text={alerts.equity[1]} />
            </div>
          )}
        </div>
      ) : null}
      {formData.schemes === "sharedOwnership" ? (
        <div className="mb-8">
          <InputPriceField
            name="propertyPurchasePrice"
            label={
              <span>
                How much is the full property purchase price?
                <br />
                This includes the share
              </span>
            }
            help={
              <p className="text-md text-white">
                This can be an actual purchase price or an estimation.
              </p>
            }
            value={formData["propertyPurchasePrice"]}
            onChange={handlePriceChange}
            error={errors["propertyPurchasePrice"]}
          />
        </div>
      ) : null}
      {/*  {!resetDepositField && formData.sharedOwnership !== 1 ? ( */}
      {!resetDepositField ? (
        <div className="mb-8">
          <InputPriceField
            name="deposit"
            label="How much deposit do you have for your purchase?"
            help={
              <p className="text-md text-white">
                This could be savings, or gifted from family
                {formData.propertyToSell
                  ? ". Adjust by adding funds or subtracting existing equity"
                  : null}
                .
              </p>
            }
            value={formData["deposit"]}
            onChange={handlePriceChange}
            error={errors["deposit"]}
            alert={alerts.deposit}
          />
          {alerts.deposit && (
            <div className="mt-1">
              <Alert type={alerts.deposit[0]} text={alerts.deposit[1]} />
            </div>
          )}
          {alerts.equityDeposit && (
            <div className="mt-1">
              <Alert
                type={alerts.equityDeposit[0]}
                text={alerts.equityDeposit[1]}
              />
            </div>
          )}
        </div>
      ) : null}
      {/* {formData.sharedOwnership !== 1 ? ( */}
      <div className="mb-8">
        <InputPriceField
          name="income"
          label="Please tell us about your income?"
          help={
            <>
              <p className="text-md mb-2 text-white">
                This should be your gross annual income and should include any
                overtime, bonuses, and shift allowance, etc.
              </p>
              <p className="text-md mb-2 text-white">
                For self employed and contractor incomes this will be your
                profit as stated on your latest tax circulation (SA302).
              </p>
              <p className="text-md mb-0 text-white">
                For joint purchases this should be the combined total of both
                incomes.
              </p>
            </>
          }
          value={formData["income"]}
          onChange={handlePriceChange}
          error={errors["income"]}
        />
        {alerts.income && (
          <div className="mt-1">
            <Alert type={alerts.income[0]} text={alerts.income[1]} />
          </div>
        )}
      </div>

      <div className="mb-4">
        <button
          className="inline-flex w-full max-w-sm items-center justify-center whitespace-nowrap rounded-full bg-emerald-500 p-4 text-xl font-semibold uppercase leading-none text-white  transition-colors duration-500  hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 dark:hover:bg-emerald-400 disabled:dark:bg-gray-600 disabled:dark:text-gray-800"
          onClick={() => {
            setShowResults(true);
            document.getElementById("root").scrollIntoView();
          }}
          disabled={!formComplete}
        >
          <span className="hidden xs:block">Calculate your Results</span>
          <span className="block xs:hidden">Calculate</span>
        </button>
      </div>
    </div>
  );
}

export default FormMain;
