import { useState } from "react";
import { sample } from "lodash";
import Title from "./../components/Title";
import reviews from "../data/reviews.json";
import TrustpilotLogo from "./svg/TrustpilotLogo";

const BlockReview = () => {
  const [review] = useState(sample(reviews));

  return (
    <>
      <div className="bg-gray-100 px-4 pb-16 pt-16 md:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-3xl bg-gray-100">
          <div className="mb-8">
            <Title title="What our customers say" />
          </div>
          <div className="gradient-45 w-full rounded-3xl p-[2px]">
            <div className="w-full rounded-[calc(1.5rem-2px)] bg-gray-100 p-8 text-left text-black transition-colors">
              <div className="flex flex-col sm:flex-row">
                <div className="mb-4 sm:mr-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-auto w-12 text-gray-300 dark:text-gray-700"
                    viewBox="0 0 448 512"
                    fill="currentColor"
                  >
                    <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64h-64c-35.3 0-64-28.7-64-64V216z" />
                  </svg>
                </div>
                <div>
                  <p className="text-md mb-2 font-semibold text-black dark:text-white">
                    {review.reviewTitle}
                  </p>
                  <p className="text-md mb-4 text-black dark:text-white">
                    {review.review}
                  </p>

                  <a
                    className="mb-0"
                    href="https://www.trustpilot.com/review/thresholdmortgages.com"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    <TrustpilotLogo />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="cur -mt-12 h-12 sm:-mt-16 sm:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full rotate-180 fill-current text-white"
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div> */}
    </>
  );
};

export default BlockReview;
